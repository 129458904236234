import { render, staticRenderFns } from "./GoldOverview.vue?vue&type=template&id=5d66cbae&scoped=true&"
import script from "./GoldOverview.vue?vue&type=script&lang=ts&"
export * from "./GoldOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d66cbae",
  null
  
)

export default component.exports
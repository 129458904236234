













import Vue from "vue";
import PvpForm from "@/components/booking/form/PvpForm.vue";

export default Vue.extend({
  name: "Pvp",
  components: { PvpForm },
});

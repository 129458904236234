












































































































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { Customer, Raid } from "@/interfaces/Raids";
import RaidBookingMenu from "@/components/booking/RaidBookingMenu.vue";
import { mailedOptions } from "@/shared/constants";
import moment from "moment/moment";

export default Vue.extend({
    name: "AdvertiserOverviewForm",
    components: { RaidBookingMenu },
    data: () => ({
        valid: false,
        runs: [],
        paymentRealm: null,
        collector: null,
        mailed: null,
        runId: null,
        loading: false,
        snackbar: false,
        snackbarText: "Should not show",
        snackbarColor: "red",
        timeout: 2000,
        advFilter: null,
        textRules: [
            (v: string) => !!v || "Field is required",
            (v: string) =>
                (!!v && v.replace(/\s/g, "").length > 1) ||
                "Name must be at least 1 non-whitespace character"
        ],
        required: [(v: any) => !!v || "Field is required"],
    }),
    methods: {
        goBack: function(e) {
            e.preventDefault();
            this.$router.go(-1)
        },
        loadRuns: function() {
            this.runs = this.$store.state.raids;
        },
        snackbarToggle: function(snackbarData: SnackbarData) {
            this.snackbarText = snackbarData.message;
            this.snackbarColor = snackbarData.color;
            this.snackbar = true;
        }
    },
    computed: {
        moment: function() {
            return moment
        },
        advOptions: function() {
            const bookings = this.history as Array<Customer>;
            return bookings.map((c: Customer) => {
                return c.advertiser.username;
            })
        },
        mailedOptions: function() {
            return mailedOptions
        },
        history: function() {
            const bookings: Array<Customer> = [];
            this.runs.forEach((raid: Raid) => {
                bookings.push(...[...raid.Buyer, ...(raid.Backup || [])].map((c: Customer) => {
                    c.raid = raid;
                    return c;
                }).filter((booking: Customer) => {
                    let filtered = true;
                    if(this.runId !== null && filtered){
                        if((booking.raid as Raid).id === this.runId){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }
                    if(this.advFilter !== null && filtered){
                        if(booking.advertiser.username === this.advFilter){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }
                    return filtered;
                }))
            })
            return bookings;
        },
        runIdsOptions: function() {
            const  bookings: Array<Customer> = this.history;
            return bookings.map((booking: Customer) => {
                return typeof booking.raid === "string"? booking.raid : booking.raid.id;
            });
        }
    },
    mounted: function() {
        this.$store.dispatch("getRaids");
    },
    watch: {
        '$store.state.raids': {
            deep: true,
            handler: function() {
                this.loadRuns();
            }
        }
    }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"position":"relative"},attrs:{"fluid":""}},[_c('v-form',{ref:"form",staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"table pb-md-0 mb-10"},[_c('v-row',{staticClass:"white--text py-3 pl-3 sticky-row",staticStyle:{"border-bottom":"1px solid white"}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","md":"1"}},[_c('h3',[_vm._v("Filters:")])]),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-text-field',{attrs:{"dense":"","dark":"","outlined":"","label":"DiscordId","clearable":"","hide-details":"true"},model:{value:(_vm.discordId),callback:function ($$v) {_vm.discordId=$$v},expression:"discordId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":_vm.$vuetify.breakpoint.smAndDown ? 0 : 40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":"","rules":_vm.dateRules,"label":"Start Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":true,"nudge-right":_vm.$vuetify.breakpoint.smAndDown ? 0 : 40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dark":"","rules":_vm.dateRules,"label":"End Date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"color":"green"},on:{"click":function($event){return _vm.getSpecificNameChangeLogs()}}},[_vm._v("Search")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 frame",attrs:{"items":_vm.nameChangeLogs,"headers":_vm.headers,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:"item.dateHumanReadable",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.dateHumanReadable))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
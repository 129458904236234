
































import Balance from "@/components/booster/balances/Balance.vue";
import Vue from "vue";
export default Vue.extend({
  name: "Balances",
  components: { Balance },
  props: ["balances"],
  data: () => ({
    tab: null,
  }),
});

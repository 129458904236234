

































































































































































































































































































































import { categoryOptions, coachingCategoryOptions, extendedAdvCutOptions, factions, memberCutOptions, mplusAdvCutOptions, paymentFactionOptions, pvpServiceOptions, realmOptions, specOptions } from "@/shared/constants";
import Vue from "vue";
import GoldTextField from "@/components/custom/gold-text-field";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { formatPotToGold } from "@/shared/formatPotToGold";
import SummaryOverlay from "@/components/booking/form/custom/SummaryOverlay.vue";

export default Vue.extend({
  name: "PvpForm",
  components: { SummaryOverlay, GoldTextField },
  data: () => ({
    valid: false,
    faction: null,
    factions: factions,
    advCut: "",
    advCutOptions: mplusAdvCutOptions,
    checkPvp: "",
    title: "",
    boostTitle: "",
    pvpServiceOptions: pvpServiceOptions,
    spec: "",
    specOptions: specOptions,
    hours: "",
    wins: "",
    category: "",
    categoryOptions: categoryOptions,
    coachingCategoryOptions: coachingCategoryOptions,
    pot: "",
    note: "",
    start: "",
    end: "",
    paymentRealm: "",
    paymentRealmOptions: realmOptions,
    paymentFaction: "",
    paymentFactionOptions: paymentFactionOptions,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    textRules: [
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    checkPvpRules: [
      (v: string) => {
        if (!v) {
          return "Field is required";
        }
        try {
          const checkPvpUrl = new URL(v.replace(/\s+/g, ""));
          if (checkPvpUrl.hostname !== "check-pvp.fr") {
            return "Make sure the url is from check-pvp.fr";
          }
          const pathnamePaths = checkPvpUrl.pathname.split("/");
          if (
            pathnamePaths.length === 4 &&
            pathnamePaths[1] === "eu"
          ) {
            return true;
          } else {
            return "Character not from europe";
          }
        } catch (e) {
          return "Could not parse URL";
        }
      },
    ],
    required: [(v: any) => !!v || "Field is required"],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && realmOptions.includes(v)) || "Unknown Realm selected",
    ],
    specRule: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && specOptions.includes(v)) || "Unknown spec selected",
    ],
  }),
  methods: {
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm() {
      const form: any = this.$refs.form;
      form.reset();
    },
    async bookCustomer(shouldBook: boolean) {
      const form: any = this.$refs.form;
      this.title = this.boostTitle
      if(this.boostTitle.toLowerCase() == "wins"){
        this.title += " " + this.wins +" wins Startrating:" + this.start
      }
      if(this.boostTitle.toLowerCase() == "coaching"){
        this.title += " " + this.hours +" hours"
      }
      if(this.boostTitle.toLowerCase() == "rating"){
        this.title += ": " +this.start +" - "+this.end + " in " + this.category
      }
      if (shouldBook) {
        this.loading = true;
        form.validate();
        const response = await this.$store.dispatch("createBooking", {
          section: "pvp",
          faction: this.faction,
          title: this.title,
          typeOfBoost: this.boostTitle,
          pot: Math.floor(parseInt(this.pot)*1000).toString(),
          payrealm: this.paymentRealm,
          payfaction: this.paymentFaction,
          advcut: this.advCut,
          checkpvp: this.checkPvp,
          spec: this.spec,
          category: this.category.toLowerCase(),
          start: this.start,
          end: this.end,
          hours: this.hours,
          wins: this.wins,
          note: this.note,
        });
        if (response.status >= 200 && response.status < 400) {
          this.snackbarToggle({
            message: "Your booking has been successfully created.",
            color: "green",
          });
          // form.reset();
        } else {
          this.snackbarToggle({
            message: "Your booking failed! Please try again later.",
            color: "red",
          });
        }
        this.loading = false;
      }
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
  },
  computed: {
    computeSummaryValues: function () {
      const values: any = [];
      values.push({ label: "Check-pvp", value: this.checkPvp });
      values.push({ label: "Category", value: this.category });
      values.push({ label: "Start Rating", value: this.start });
      values.push({ label: "End Rating", value: this.end });
      values.push({
        label: "Price",
        value: formatPotToGold((parseFloat(this.pot) * 1000).toString()),
      });
      values.push({ label: "Note", value: this.note });
      return values;
    },
  },
  mounted: function(){
    this.$nextTick(function () {
      this.advCut = this.$store.state.roles.some((role) => role === 'TAKENBOOKER') ?  "" : memberCutOptions[0]
      this.advCutOptions = this.$store.state.roles.some((role) => role === 'TAKENBOOKER') ?  mplusAdvCutOptions : memberCutOptions
    })
  },
});















import ShiftOverviewForm from "@/components/booking/form/ShiftOverviewForm.vue";
import Vue from "vue";

export default Vue.extend({
    name: "ShiftOverview",
    components: { ShiftOverviewForm }
});

<template>
  <v-col cols="12">
    <v-row
      align="center"
      justify="center"
      style="border-bottom: 1px solid #6381b4"
    >
      <v-col md="1">
        <div class="white--text text-sm-body-1 text-center">
          {{ attendance.date }}
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ attendance.typeOfBoost }}
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" :title="attendance.buyerName" >
          {{ attendance.buyerName }}
        </div>
        <div class="grey--text text--lighten-1 text-sm-subtitle-1 text-center">
          Buyer
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ formatGold(attendance.price) }}
        </div>
        <div class="grey--text text--lighten-1 text-sm-subtitle-1 text-center">
          Price
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ attendance.cutOption }}
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ formatGold(attendance.yourCut) }}
        </div>
        <div class="grey--text text--lighten-1 text-sm-subtitle-1 text-center">
          Your Cut
        </div>
      </v-col>
      <v-col md="1">
        <v-tooltip bottom v-if="attendance.validation === 'TRUE' || attendance.validation === '1'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green" size="32" v-bind="attrs" v-on="on"
              >mdi-check</v-icon
            >
          </template>
          <span>Verified</span>
        </v-tooltip>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="white" size="32" v-bind="attrs" v-on="on"
              >mdi-help</v-icon
            >
          </template>
          <span>Unverified</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { formatPotToGold } from "@/shared/formatPotToGold";
import Vue from "vue";

export default Vue.extend({
  name: "AdvertisementRow",
  props: ["attendance"],
  methods: {
    formatGold(value) {
      return formatPotToGold(value.toString());
    },
  },
});
</script>

<style scoped></style>

import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=02d839a3&scoped=true&"
import script from "./AppFooter.vue?vue&type=script&lang=ts&"
export * from "./AppFooter.vue?vue&type=script&lang=ts&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=02d839a3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02d839a3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFooter } from 'vuetify/lib/components/VFooter';
installComponents(component, {VBtn,VCard,VCardText,VFooter})

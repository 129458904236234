













import Vue from "vue";
import ShiftHistoryForm from "@/components/booking/form/ShiftHistoryForm.vue";

export default Vue.extend({
    name: "ShiftHistory",
    components: { ShiftHistoryForm }
});

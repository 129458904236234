<template>
  <div class="callback">Callback</div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Callback",
  mounted() {
    this.$router.push("/");
  },
});
</script>

<style scoped></style>















import Vue from "vue";
import LevelingForm from "@/components/booking/form/LevelingForm.vue";

export default Vue.extend({
  name: "Leveling",
  components: { LevelingForm },
});

export default  {
    formatPrice: (price: number): string => {
        return new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            currencyDisplay: "name"
        }).format(price / 1000)
            .replace("US dollars", "k");
    },
    buildParams: (data): string => {
        const params = new URLSearchParams()

        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                value.forEach(value => params.append(key, value.toString()))
            } else {
                if (value) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    params.append(key, value.toString())
                }
            }
        });

        return params.toString()
    }
}















import Vue from "vue";
import LegacyForm from "@/components/booking/form/LegacyForm.vue";

export default Vue.extend({
  name: "Legacy",
  components: { LegacyForm },
});

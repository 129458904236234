




















import Vue from "vue";
import RaidRoleOverviewList from "@/components/raidmanagement/RaidRoleOverviewList.vue";

export default Vue.extend({
  name: "RaidRoleOverview",
  components: { RaidRoleOverviewList },
});

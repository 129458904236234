

















































































































































































































































import {
  classOptions,
  factions,
  websiteAdvCutOptions,
  realmOptions
} from "@/shared/constants";
import Vue from "vue";
import GoldTextField from "@/components/custom/gold-text-field";
import SummaryOverlay from "@/components/booking/form/custom/SummaryOverlay.vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { formatPotToGold } from "@/shared/formatPotToGold";

export default Vue.extend({
  name: "LegacyForm",
  components: { SummaryOverlay, GoldTextField },
  data: () => ({
    valid: false,
    faction: null,
    factions: factions,
    advCut: "",
    advCutOptions: websiteAdvCutOptions,
    payFaction: null,
    pot: "",
    buyer: "",
    boostTitle: "",
    paymentRealm: "",
    paymentRealmOptions: realmOptions,
    paymentFaction: "",
    note: "",
    classSelected: null,
    classOptions: classOptions,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && realmOptions.includes(v)) || "Unknown Realm selected",
    ],
    required: [(v: any) => !!v || "Field is required"],
  }),
  methods: {
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm() {
      const form: any = this.$refs.form;
      form.reset();
    },
    async bookCustomer(shouldBook: boolean) {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();
        const legacyBooking = {
          section: "legacy",
          faction: this.faction,
          typeOfBoost: this.boostTitle,
          buyer: this.buyer,
          buyerClass: this.classSelected,
          paymentRealm: this.paymentRealm.replace(/\s+/g, ""),
          paymentFaction: this.paymentFaction.toUpperCase(),
          price: parseInt(this.pot) * 1000,
          advCut: this.advCut.toUpperCase(),
          note: this.note,
        };
        const response = await this.$store.dispatch(
          "createBooking",
          legacyBooking
        );
        if (response.status >= 200 && response.status < 400) {
          this.snackbarToggle({
            message: "Your booking has been successfully created.",
            color: "green",
          });
          form.reset();
        } else {
          this.snackbarToggle({
            message: "Your booking failed! Please try again later.",
            color: "red",
          });
        }
        this.loading = false;
      }
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
  },
  computed: {
    computeSummaryValues: function () {
      const values: any = [];
      values.push({ label: "Faction", value: this.faction });
      values.push({ label: "Customer", value: this.buyer });
      values.push({ label: "Type Of Service", value: this.boostTitle});
      values.push({ label: "FeeOption", value: this.advCut });
      values.push({
        label: "Price",
        value: formatPotToGold((parseFloat(this.pot) * 1000).toString()),
      });
      values.push({ label: "Payment Realm", value: this.paymentRealm });
      values.push({ label: "Payment Faction", value: this.paymentFaction });
      values.push({ label: "Note", value: this.note });
      return values;
    },
  },
  // mounted: function(){
  // this.$nextTick(function () {
  //     this.advCut = this.$store.state.roles.some((role) => role === 'TAKENBOOKER') ?  "" : memberCutOptions[0]
  //     this.advCutOptions = this.$store.state.roles.some((role) => role === 'TAKENBOOKER') ?  extendedAdvCutOptions : memberCutOptions
  //   })
  // },
  // watch: {
  // }
});

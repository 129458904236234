<template>
  <v-card
    class="rounded-lg"
    style="position: relative; background-color: #2f4975"
  >
    <v-img
      style="position: relative"
      :src="require(`@/assets/Raid/cancelcomplete.png`)"
      min-height="200"
      max-height="200"
    >
      <v-container
        fill-height
        style="
          background-color: rgba(80, 80, 80, 0.6);
          position: absolute;
          width: 100%;
          height: 100%;
        "
      >
        <v-row align="center" justify="center">
          <v-col cols="12" class="white--text text-h3 text-center">
            Raid Logs
          </v-col>
        </v-row>
      </v-container>
    </v-img>
    <v-card-text class="white--text">
      <ul>
        <li>Check logs for a specific raid</li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "LogsCard",
};
</script>

<style scoped>
ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>








































































































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { Customer, Raid, Shift } from "@/interfaces/Raids";
import moment from "moment-timezone";
import { Cycle, CycleOption } from "@/interfaces/Cycle";

export default Vue.extend({
  name: "CycleHistoryForm",
  components: {},
  data: () => ({
    valid: false,
    runs: [],
    shifts: [],
    cycle: null,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
  }),
  methods: {
    goBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    async loadRuns() {
      this.runs = this.$store.state.raids;
      this.shifts = this.$store.state.shifts;
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    amountOwned: function (booking: Customer) {
      let amountOwnedPerBooking = 0;
      if (booking.advCut === "FTB") {
        amountOwnedPerBooking = Number(
          (BigInt(booking.price) * BigInt(275)) / BigInt(1000)
        );
      } else if (booking.advCut === "TAKEN") {
        amountOwnedPerBooking = Number(
          (BigInt(booking.price) * BigInt(300)) / BigInt(1000)
        );
      } else {
        amountOwnedPerBooking =
          Number((BigInt(booking.price) * BigInt(100)) / BigInt(1000)) >
          BigInt(100000)
            ? 100000
            : Number((BigInt(booking.price) * BigInt(100)) / BigInt(1000));
      }
      return amountOwnedPerBooking;
    },
    formatDate: function (date: string) {
      return moment(date).format("MM-DD");
    },
  },
  computed: {
    cycleData: function () {
      return this.$store.state.cycleDateInfo;
    },
    history: function () {
      const bookings: Array<Customer> = [];
      const filteredRuns: Array<Raid> = this.runs.filter((raid: Raid) => {
        let filter = true;
        if (this.cycle) {
          if (
            (this.cycle as any).start &&
            (this.cycle as any).end &&
            raid?.date &&
            filter
          ) {
            if (
              new Date(raid?.date) >= (this.cycle as any)?.start &&
              new Date(raid?.date) <= (this.cycle as any)?.end
            ) {
              filter = true;
            } else {
              filter = false;
            }
          }
        }
        return filter;
      });

      filteredRuns.forEach((raid: Raid) => {
        const allBooking: Array<Customer> = [
          ...raid.Buyer,
          ...(raid.Backup || []),
        ];
        allBooking.map((booking: Customer) => {
          booking.raid = raid;
        });
        bookings.push(...allBooking);
      });

      // const filteredShifts: Array<Shift> = this.shifts.filter((raid: Shift) => {
      //     let filter = true;
      //     if (this.cycle) {
      //         if ((this.cycle as any).start && (this.cycle as any).end && raid?.date && filter) {
      //             if (new Date(raid?.date) >= (this.cycle as any)?.start && new Date(raid?.date) <= (this.cycle as any)?.end) {
      //                 filter = true;
      //             } else {
      //                 filter = false;
      //             }
      //         }
      //     }
      //     return filter;
      // });

      // filteredShifts.forEach((raid: Shift) => {
      //     const allBooking: Array<Customer> = [...raid.Buyer, ...(raid.Backup || [])];
      //     allBooking.map((booking: Customer) => {
      //         booking.raid = raid;
      //     });
      //     bookings.push(...allBooking);
      // });

      return bookings
        .filter((booking: Customer) => {
          return (
            booking.completed &&
            booking.advertiser?.id == this.$store.state.user.id
          );
        })
        .sort((a, b): number => {
          return a.paymentRealm.localeCompare(b.paymentRealm);
        });
    },
    revueAmount: function () {
      let sum = 0;
      const bookings: Array<Customer> = this.history;
      bookings.forEach((booking: Customer) => {
        if (booking.price) {
          sum += booking.price;
        }
      });
      return sum;
    },
    amountBookings: function () {
      const bookings: Array<Customer> = this.history;
      return bookings.length;
    },
    dateRangeOptions: function () {
      if (this.cycleData) {
        let startCurrentCycle = moment(this.cycleData.currentStart);
        let endCurrentCycle = moment(this.cycleData.currentEnd);
        let startOldCycle = moment(this.cycleData.oldStart);
        let endOldCycle = moment(this.cycleData.oldEnd);
        const dateRanges: Array<CycleOption> = [];
        const currentCycle: CycleOption = {
          start: startCurrentCycle.format("YYYY-MM-DD"),
          end: endCurrentCycle.format("YYYY-MM-DD"),
          display:
            "Current (" +
            moment(this.cycleData.currentStart).format("DD/MM") +
            " - " +
            moment(this.cycleData.currentEnd).format("DD/MM") +
            ")",
        };
        const oldCycle: CycleOption = {
          start: startOldCycle.format("YYYY-MM-DD"),
          end: endOldCycle.format("YYYY-MM-DD"),
          display:
            "Old (" +
            moment(this.cycleData.oldStart).format("DD/MM") +
            " - " +
            moment(this.cycleData.oldEnd).format("DD/MM") +
            ")",
        };

        const threeWeeksOldCycle: CycleOption = {
          start: startOldCycle.clone().subtract(7, "days").format("YYYY-MM-DD"),
          end: endOldCycle.clone().subtract(7, "days").format("YYYY-MM-DD"),
          display:
            "Old (" +
            startOldCycle.clone().subtract(7, "days").format("DD/MM") +
            " - " +
            endOldCycle.clone().subtract(7, "days").format("DD/MM") +
            ")",
        };
        const fourWeeksOldCycle: CycleOption = {
          start: startOldCycle
            .clone()
            .subtract(14, "days")
            .format("YYYY-MM-DD"),
          end: endOldCycle.clone().subtract(14, "days").format("YYYY-MM-DD"),
          display:
            "Old (" +
            startOldCycle.clone().subtract(14, "days").format("DD/MM") +
            " - " +
            endOldCycle.clone().subtract(14, "days").format("DD/MM") +
            ")",
        };
        const fiveWeeksOldCycle: CycleOption = {
          start: startOldCycle
            .clone()
            .subtract(21, "days")
            .format("YYYY-MM-DD"),
          end: endOldCycle.clone().subtract(21, "days").format("YYYY-MM-DD"),
          display:
            "Old (" +
            startOldCycle.clone().subtract(21, "days").format("DD/MM") +
            " - " +
            endOldCycle.clone().subtract(21, "days").format("DD/MM") +
            ")",
        };
        const sixWeeksOldCycle: CycleOption = {
          start: startOldCycle
            .clone()
            .subtract(28, "days")
            .format("YYYY-MM-DD"),
          end: endOldCycle.clone().subtract(28, "days").format("YYYY-MM-DD"),
          display:
            "Old (" +
            startOldCycle.clone().subtract(28, "days").format("DD/MM") +
            " - " +
            endOldCycle.clone().subtract(28, "days").format("DD/MM") +
            ")",
        };
        dateRanges.push(currentCycle);
        dateRanges.push(oldCycle);
        dateRanges.push(threeWeeksOldCycle);
        dateRanges.push(fourWeeksOldCycle);
        dateRanges.push(fiveWeeksOldCycle);
        dateRanges.push(sixWeeksOldCycle);
        return dateRanges;
      }
      return [];
    },
  },
  mounted: async function () {
    this.$nextTick(function () {
      this.$store.dispatch("getCycleDate");
      this.$store.dispatch("getRaids", {
        removeFilter: ["completed"],
        start: moment()
          .tz("Europe/Berlin")
          .subtract(7, "day")
          .format("YYYY-MM-DD"),
      });
      this.$store.dispatch("getShifts", {
        removeFilter: ["completed"],
        start: moment()
          .tz("Europe/Berlin")
          .subtract(7, "day")
          .format("YYYY-MM-DD"),
      });
    });
  },
  watch: {
    "$store.state.raids": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
    "$store.state.shifts": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
    cycle: function () {
      if (this.cycle) {
        this.$store.dispatch("getRaids", {
          removeFilter: ["completed"],
          start: (this.cycle as any).start,
          end: (this.cycle as any).end,
        });
        this.$store.dispatch("getShifts", {
          removeFilter: ["completed"],
          start: moment((this.cycle as any).start).format("YYYY-MM-DD"),
          end: moment((this.cycle as any).end).format("YYYY-MM-DD"),
        });
      }
    },
  },
});















import Vue from "vue";
import BookingAndHistoryForm from "@/components/booking/form/BookingAndHistoryForm.vue";

export default Vue.extend({
  name: "BookingAndHistory",
  components: { BookingAndHistoryForm },
});









































import ViewAsBooster from "@/components/booster/ViewAsBooster.vue";
import LoyaltySystemHistory from "@/components/loyaltysystem/LoyaltySystemHistory.vue";
import Vue from "vue";

export default Vue.extend({
  name: "LoyaltySystem",
  components: {
    ViewAsBooster,
    LoyaltySystemHistory,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    loyaltyPoints() {
      return this.$store.state.loyaltySystem;
    },
    avatarUrl() {
      const { id, avatar } = this.$store.state.user;
      return `https://cdn.discordapp.com/avatars/${id}/${avatar}${
        avatar.startsWith("a_") ? ".gif" : ".png"
      }`;
    },
  },
  methods: {
    async loadAsBooster(discordId: string) {
      await this.$store.dispatch("getLoyaltyDataAsAdmin", discordId);
    },
  },
  data: () => ({
    drawer: true,
  }),
  mounted: async function () {
    this.$nextTick(function () {
      this.$store.dispatch("getLoyaltyData");
    });
  },
});

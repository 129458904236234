<template>
  <v-container style="position: relative" fluid>
    <v-form v-model="valid" style="width: 100%" ref="form">
      <div class="table pb-md-0 mb-10">
        <v-row
          class="white--text py-3 pl-3 sticky-row"
          style="border-bottom: 1px solid white"
        >
          <v-col cols="12" md="1" class="d-flex align-center">
            <h3>Filters:</h3>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="2"
            class="d-flex justify-center align-center"
          >
            <v-text-field
              dense
              dark
              v-model="discordId"
              outlined
              label="DiscordId"
              clearable
              hide-details="true"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="startDate"
                  :rules="dateRules"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="2">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="endDate"
                  :rules="dateRules"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="endDate"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn color="green" @click="getSpecificNameChangeLogs()"
              >Search</v-btn
            >
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :items="nameChangeLogs"
        :headers="headers"
        :items-per-page="10"
        class="elevation-1 frame"
        dense
      >
        <template v-slot:item.dateHumanReadable="{ item }">
          <span>{{ item.dateHumanReadable }}</span>
        </template>
      </v-data-table>
    </v-form>
  </v-container>
</template>

<script>
import { getDateObjectFromPickerValue } from "@/shared/getDateObjectFromPickerValue";
import Vue from "vue";

export default Vue.extend({
  name: "NameChangeLogs",
  data: () => ({
    valid: false,
    nameChangeLogs: [],
    discordId: null,
    startDate: null,
    endDate: null,
    loaded: false,
    dateRules: [(v) => !!v || "Date is required"],
    headers: [
      { text: "ID", value: "id" },
      { text: "Description", value: "description" },
      { text: "Date", value: "dateHumanReadable" },
    ],
  }),
  async mounted() {
    await this.fetchNameChangeLogs();
  },
  methods: {
    async fetchNameChangeLogs(info = {}) {
      try {
        let response = await this.$store.dispatch("getNameChangeLogs", info);
        if (response.status === 200) {
          this.nameChangeLogs = response.data;
        } else {
          console.error("Error loading logs:", response.message);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },
    async getSpecificNameChangeLogs() {
      let info = {
        id: this.discordId || undefined,
        startDate: this.startDate
          ? getDateObjectFromPickerValue(this.startDate)
          : undefined,
        endDate: this.endDate
          ? getDateObjectFromPickerValue(this.endDate)
          : undefined,
      };

      await this.fetchNameChangeLogs(info);
    },
  },
});
</script>

<style scoped>
.frame {
  border: 1px solid #6381b4;
  border-bottom: none;
  background: #6381b440;
  color: #ffffff;
}

::v-deep .v-data-table tr:hover {
  background-color: black !important;
  color: #ffffff;
}
</style>

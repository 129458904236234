
































































import Vue from "vue";
import MythicPlusCard from "@/components/booking/card/MythicPlusCard.vue";
import LevelingCard from "@/components/booking/card/LevelingCard.vue";
//import MountsCard from "@/components/booking/card/MountsCard.vue";
import PVPCard from "@/components/booking/card/PVPCard.vue";
import MythicCard from "@/components/booking/card/MythicCard.vue";
import LegacyCard from "@/components/booking/card/LegacyCard.vue";

export default Vue.extend({
  name: "Booking",
  components: {
    LegacyCard,
    PVPCard,
    //MountsCard,
    LevelingCard,
    MythicCard,
    MythicPlusCard,
  },
});















































































































































































































































import {
    dayOptions,
    difficultyOptions,
    raidOptions,
    raidTypeOptions,
    typeOptions,
    shiftBossOptions
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import moment from "moment/moment";
import { GoldCollector, Leader } from "@/interfaces/Raids";
import RaidManagementMenu from "@/components/booking/RaidBookingMenu.vue";

export default Vue.extend({
    name: "ShiftCreationForm",
    components: { RaidManagementMenu },
    data: () => ({
        valid: false,
        date: "",
        time: null,
        raid: raidOptions[0],
        raidOptions: raidOptions,
        gc: [],
        leader: "",
        goldCollectors: [] as Array<GoldCollector>,
        leaders: [] as Array<Leader>,
        difficulty: difficultyOptions[1],
        difficultyOptions: difficultyOptions,
        raidType: raidTypeOptions[0],
        raidTypeOptions: raidTypeOptions,
        loading: false,
        snackbar: false,
        snackbarText: "Should not show",
        snackbarColor: "red",
        timeout: 2000,
        offeredBosses: [
            {
                boss: "",
                possibleCustomers: 0,
                bossOptions: shiftBossOptions,

            }
        ],
        dateRules: [(v) => !!v || "Date is required"],
        textRules: [
            (v: string) => !!v || "Field is required",
            (v: string) =>
                (!!v && v.replace(/\s/g, "").length > 1) ||
                "Name must be at least 1 non-whitespace character"
        ],
        required: [(v: any) => !!v || "Field is required"],
        arrayRequired: [
            (v: any) => !!v || "Field is required",
            (v: any) => (!!v && v.length > 0) || "Field is required"
        ],
        bossRule: [
            (v: any) => !!v || "Field is required",
            (v: any) =>
                (!!v && shiftBossOptions.includes(v)) || "Unknown Boss selected",
        ],
    }),
    methods: {
        resetForm() {
            const form: any = this.$refs.form;
            form.reset();
        },
        removeBoss(index: number) {
            this.offeredBosses.splice(index, 1);
        },
        addBoss() {
            this.offeredBosses.push({
                boss: "",
                possibleCustomers: 0,
                bossOptions: shiftBossOptions,
            });
        },
        async createShift() {
            const form: any = this.$refs.form;
            this.loading = true;
            form.validate();
            const offeredBossesForApi = this.offeredBosses.map(({bossOptions, ...item}) => item);
            const raidDetails = {
                date: this.date,
                time: this.time,
                raid: this.raid,
                gc: this.gc,
                leader: this.leader,
                difficulty: this.difficulty,
                raidType: this.raidType,
                createdAsRecurringRaid: false,
                offeredBosses: offeredBossesForApi
            };
            const response = await this.$store.dispatch(
                "createShift",
                raidDetails
            );
            if (response.status >= 200 && response.status < 400) {
                this.snackbarToggle({
                    message: "Your raid has been successfully created.",
                    color: "green"
                });
                form.reset();
            } else {
                this.snackbarToggle({
                    message: "Your raid creation failed! Please try again later.",
                    color: "red"
                });
            }
            this.loading = false;
        },
        snackbarToggle(snackbarData: SnackbarData) {
            this.snackbarText = snackbarData.message;
            this.snackbarColor = snackbarData.color;
            this.snackbar = true;
        }
    },
    computed: {
        computeSummaryValues: function() {
            const values: any = [];
            return values;
        }
    },
    mounted: function() {
        this.$store.dispatch("getGoldCollectors");
        this.$store.dispatch("getLeaders");
        this.$nextTick(function() {
            this.date = moment().tz("Europe/Berlin").format("Y-MM-DD");
        });
    },
    watch: {
        '$store.state.goldCollectors': {
            deep: true,
            handler: function() {
                this.goldCollectors = this.$store.state.goldCollectors
            }
        },
        '$store.state.leaders': {
            deep: true,
            handler: function() {
                this.leaders = this.$store.state.leaders
            }
        }
    }
});

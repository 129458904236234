













import Vue from "vue";
import AdvertiserOverviewForm from '@/components/raidmanagement/form/AdvertiserOverviewForm.vue'

export default Vue.extend({
    name: "AdvertiserOverview",
    components: { AdvertiserOverviewForm }
});

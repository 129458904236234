



























































































































































































































































import {
  bookingTypeOptions,
  booleanOptions,
  classOptions,
  clothClasses,
  factions,
  leatherClasses,
  mailClasses,
  mplusAdvCutOptions,
  plateClasses,
  realmOptions,
  WoWClass,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import moment, { Moment } from "moment-timezone";
import GoldTextField from "@/components/custom/gold-text-field";
import { Customer, Raid } from "@/interfaces/Raids";
import { runs } from "@/helpers/fakeData";

export default Vue.extend({
  name: "RaidBookingForm",
  props: {
    raidId: String,
    bookingId: String,
  },
  components: {
    GoldTextField,
  },
  data: () => ({
    valid: false,
    faction: "",
    factions: factions,
    advCut: "",
    runID: "",
    advCutOptions: mplusAdvCutOptions,
    payFaction: null,
    pot: "",
    realm: "",
    runs: [] as Array<Raid>,
    bookings: [],
    paymentRealm: "",
    type: Object.values(bookingTypeOptions)[0],
    typeOptions: Object.values(bookingTypeOptions),
    paymentRealmOptions: realmOptions,
    paymentFaction: "",
    booleanOptions: booleanOptions,
    note: "",
    btag: "",
    buyer: "",
    collectedGold: "",
    paid: false,
    raidTypeFilter: "",
    classSelected: classOptions[0],
    classOptions: classOptions,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    selectedBooking: {} as Customer,
    selectedRaid: {} as Raid,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && realmOptions.includes(v)) || "Unknown Realm selected",
    ],
  }),
  methods: {
    moment(): Moment {
      return moment().tz("Europe/Berlin");
    },
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm() {
      const raidID = this.runID;
      const form: any = this.$refs.form;
      form.reset();
      this.$nextTick(function () {
        this.runID = raidID;
      });
    },
    async updateBooking(shouldBook: boolean): Promise<void> {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();

        if (this.selectedRaid) {
          let armorType = "Plate";
          if (mailClasses.includes(this.classSelected)) armorType = "Mail";
          if (leatherClasses.includes(this.classSelected))
            armorType = "Leather";
          if (clothClasses.includes(this.classSelected)) armorType = "Cloth";

          const booking: any = {
            ...this.selectedBooking,
            id: this.selectedBooking.id,
            section: this.selectedRaid.difficulty,
            faction: this.faction,
            typeOfBoost: this.type,
            armorType: armorType,
            buyer: this.buyer,
            buyerClass: this.classSelected,
            paymentRealm: this.paymentRealm.replace(/\s+/g, ""),
            paymentFaction: this.paymentFaction,
            price: parseInt(this.pot) * 1000,
            collectedGold: this.collectedGold
              ? parseInt(this.collectedGold) * 1000
              : 0,
            advCut: this.advCut.toUpperCase(),
            note: this.note,
            btag: this.btag,
            paid: this.paid,
            isManager: this.$store.state.roles.some((role) => {
              return (
                role.toUpperCase() === "ADVMANAGER" ||
                role.toUpperCase() === "CERTIFIED"
              );
            }),
          };

          if (!this.selectedBooking.paid && this.paid) {
            booking.goldWith = this.paid ? this.$store.state.user.id : "";
          }

          const response = await this.$store.dispatch("updateBooking", {
            booking: booking,
            raid: this.selectedRaid,
          });
          if (response.status >= 200 && response.status < 400) {
            this.snackbarToggle({
              message: "Your booking has been successfully updated.",
              color: "green",
            });

            form.reset();
          } else {
            this.snackbarToggle({
              message: "Your booking failed! Please try again later.",
              color: "red",
            });
          }
        }
        this.loading = false;
      }
    },
    async bookCustomer(shouldBook: boolean): Promise<void> {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();

        const found = this.runs.find((raid: Raid) => raid.id === this.runID);
        if (found) {
          const raid: Raid = found as Raid;
          if (raid) {
            let armorType = "Plate";
            if (mailClasses.includes(this.classSelected)) armorType = "Mail";
            if (leatherClasses.includes(this.classSelected))
              armorType = "Leather";
            if (clothClasses.includes(this.classSelected)) armorType = "Cloth";

            const booking: any = {
              section: raid.difficulty,
              faction: this.faction,
              typeOfBoost: this.type,
              armorType: armorType,
              realm: this.realm,
              buyer: this.buyer,
              buyerClass: this.classSelected,
              paymentRealm: this.paymentRealm.replace(/\s+/g, ""),
              paymentFaction: this.paymentFaction,
              price: parseInt(this.pot) * 1000,
              advCut: this.advCut.toUpperCase(),
              note: this.note,
            };

            const response = await this.$store.dispatch("bookCustomerInRaid", {
              raidId: this.runID,
              customer: booking,
            });
            if (response.status >= 200 && response.status < 400) {
              this.snackbarToggle({
                message: "Your booking has been successfully created.",
                color: "green",
              });

              raid.Buyer.push(booking);
              form.reset();
            } else {
              this.snackbarToggle({
                message: "Your booking failed! Please try again later.",
                color: "red",
              });
            }
          }
        }
        this.loading = false;
      }
    },
    async loadRuns(): Promise<void> {
      this.runs = this.$store.state.raids;
    },
    loadBooking(): void {
      const raid: Raid = this.selectedRaid;
      if (raid) {
        if (this.selectedBooking) {
          let wowClass: WoWClass = classOptions.find(
            (co) => co === this.selectedBooking.buyerClass
          ) as WoWClass;
          const paymentRealm = this.paymentRealmOptions.find(
            (realm: string) =>
              realm.replace(/\s+/g, "") === this.selectedBooking.paymentRealm
          );
          this.faction = this.selectedBooking.faction;
          this.type = this.selectedBooking.typeOfBoost;
          this.buyer = this.selectedBooking.buyer;
          this.classSelected = wowClass;
          this.paymentRealm = paymentRealm
            ? paymentRealm
            : this.paymentRealmOptions[0];
          this.paymentFaction = this.selectedBooking.paymentFaction;
          this.pot = this.selectedBooking.price / 1000 + "";
          this.collectedGold = this.selectedBooking.collectedGold
            ? this.selectedBooking.collectedGold / 1000 + ""
            : "";
          this.advCut = this.selectedBooking.advCut;
          this.note = this.selectedBooking.note
            ? this.selectedBooking.note
            : "";
          this.paid = this.selectedBooking.paid
            ? this.selectedBooking.paid
            : false;
          this.runID = "" + raid.id;
        }
      }
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
  },
  computed: {
    computeSummaryValues: function () {
      const values: any = [];
      values.push({ label: "Faction", value: this.faction });
      values.push({ label: "FeeOption", value: this.advCut });
      values.push({ label: "Payment Realm", value: this.paymentRealm });
      values.push({
        label: "Payment Faction",
        value: this.paymentFaction,
      });
      return values;
    },
    getAmountOfTradersLabel: function () {
      if (plateClasses.includes(this.classSelected)) {
        return "Total Plate Traders";
      } else if (mailClasses.includes(this.classSelected)) {
        return "Total Mail Traders";
      } else if (leatherClasses.includes(this.classSelected)) {
        return "Total Leather Traders";
      } else {
        return "Total Cloth Traders";
      }
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.loadRuns().then(() => {
        const raid = this.runs.find((r: Raid) => r.id === this.raidId);
        if (raid) {
          this.selectedRaid = raid;

          let booking = raid.Buyer.find(
            (c: Customer) => c.id === this.bookingId
          );
          if (booking) {
            this.selectedBooking = booking;
            this.loadBooking();
          } else {
            booking = raid.Backup?.find(
              (c: Customer) => c.id === this.bookingId
            );
            if (booking) {
              this.selectedBooking = booking;
              this.loadBooking();
            }
          }
        }
      });
    });
  },
});












































































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";

export default Vue.extend({
  name: "AddOrDeleteItemsForm",
  components: {},
  data: () => ({
    valid: false,
    cycle: null,
    items: [
      {
        label: "",
        description: "",
        url: "",
        amount: "",
        category: "",
      },
    ],
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    categoryOptionsArray: [] as Array<string>,
    timeout: 2000,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
  }),
  methods: {
    removeItem(index: number) {
      this.items.splice(index, 1);
    },
    addItem() {
      this.items.push({
        label: "",
        description: "",
        url: "",
        amount: "",
        category: "",
      });
    },
    async saveItems() {
      const form: any = this.$refs.form;
      this.loading = true;
      form.validate();
      const response = await this.$store.dispatch("saveItems", this.items);
      if (response.status >= 200 && response.status < 400) {
        this.snackbarToggle({
          message: "The items have been successfully saved.",
          color: "green",
        });
      } else {
        this.snackbarToggle({
          message: "The items couldn't be saved! Please try again later.",
          color: "red",
        });
      }
      this.loading = false;
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    async loadItems() {
      const response = await this.$store.dispatch("getItemsForAdmin", {});
      if (response.status >= 400) {
        this.$emit("snackbarToggle", {
          message: "No available items found",
          color: "red accent-1",
        });
      }
      this.items = response.data;
    },
  },
  computed: {
    categoryOptions: function (): Array<any> {
      const categoriesSet = new Set(
        this.categoryOptionsArray.map((category) => category.trim())
      );
      this.items.forEach((item: any) => {
        const trimmedCategory = item.category.trim();
        if (trimmedCategory && !categoriesSet.has(trimmedCategory)) {
          categoriesSet.add(trimmedCategory);
        }
      });
      return Array.from(categoriesSet);
    },
  },
  mounted: function () {
    this.$nextTick(function () {
      this.loadItems();
    });
  },
  watch: {},
});

































































































































































































































































































































































































































































import {
  customerGroupStatuses,
  dayOptions,
  difficultyOptions,
  typeOptions,
} from "@/shared/constants";
import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { Customer, Day, Raid, RaidDifficulties } from "@/interfaces/Raids";
import moment from "moment";
import { runs } from "@/helpers/fakeData";
import RaidBookingForm from "@/components/raidmanagement/form/RaidBookingForm.vue";
import RaidManagementButtons from "@/components/raidmanagement/RaidManagementButtons.vue";
import { Moment } from "moment/moment";

export default Vue.extend({
  name: "YourRunHistoryOverviewForm",
  components: { RaidManagementButtons },
  data: () => ({
    runs: [] as Array<Raid>,
    valid: false,
    selectedRun: {} as Raid,
    loading: false,
    snackbar: false,
    typeOptions: typeOptions,
    difficulties: difficultyOptions,
    customerGroupStatuses: customerGroupStatuses,
    dayOptions: dayOptions,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    dayFilter: [] as Array<string>,
    runId: null,
    completedFilter: false,
    cancelledFilter: false,
    editDialog: false,
    prevRoute: "",
    hiddenRaidsData: [] as Array<string>,
  }),
  methods: {
    totalPot(bookings: Array<Customer>) {
      let sum = 0;
      bookings.forEach((booking: Customer) => {
        if (
          booking.goldWith &&
          booking.paid &&
          booking.customerStatus === "In group" &&
          booking.completed
        ) {
          sum += booking.price;
        }
      });
      return sum;
    },
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    loadRuns: function () {
      this.runs = this.$store.state.raids;
      return this.$store.state.raids;
    },
    copyName(bookings: Array<Customer>) {
      navigator.clipboard.writeText(bookings.map((b) => b.buyer).join("\r\n"));
      this.snackbarToggle({
        message: "Customer name copied!",
        color: "green",
      });
    },
    getAllBooking(raid: Raid): Array<Customer> {
      let buyers: Array<Customer> = raid.Buyer;
      let backups: Array<Customer> = raid.Backup as Array<Customer>;
      let merged: Array<Customer> = buyers.map((c: Customer) => {
        c.raid = raid.id;
        return c;
      });
      if (backups && backups.length > 0) {
        merged = merged.concat(
          backups.map((c: Customer) => {
            c.raid = raid.id;
            c.backup = true;
            return c;
          })
        );
      }
      return merged;
    },
    goBack(e) {
      e.preventDefault();
      this.$router.go(-1);
    },
    toggleRaid(raid: Raid) {
      if (this.hiddenRaids.includes(raid.id)) {
        const removeArray = this.hiddenRaids;
        removeArray.splice(removeArray.indexOf(raid.id), 1);
        localStorage.setItem("hiddenRaids", JSON.stringify(removeArray));
        this.hiddenRaidsData = removeArray;
      } else {
        const addArray = this.hiddenRaids;
        addArray.push(raid.id);
        localStorage.setItem("hiddenRaids", JSON.stringify(addArray));
        this.hiddenRaidsData = addArray;
      }
    },
    moment: function (date) {
      return moment(date);
    },
    activeBuyerCount(raid: Raid) {
      let sum = 0;
      this.getAllBooking(raid).forEach((c: Customer) => {
        if (
          c.customerStatus !== "Cancelled" &&
          c.customerStatus !== "Backup" &&
          c.customerStatus !== "AFK" &&
          c.customerStatus !== "Offline" &&
          c.customerStatus !== "Duplicate"
        ) {
          sum += 1;
        }
      });
      return sum;
    },
  },
  computed: {
    runIdsOptions: function () {
      const raids: Array<Raid> = this.filteredRaids as Array<Raid>;
      return raids.map((raid: Raid) => {
        const date = this.moment(raid.date) as Moment;
        return {
          id: raid.id,
          text: `${date.format("Y-MM-DD")}, ${raid.day} ${raid.time} - ${
            raid.difficulty
          }, ${raid.type}`,
        };
      });
    },
    hiddenRaids(): Array<string> {
      return this.hiddenRaidsData;
    },
    filteredRaids: function () {
      const raids: Array<Raid> = [];
      this.loadRuns().forEach((raid: Raid) => {
        let need = true;
        if (this.runId !== null && need) {
          if (raid.id === this.runId) {
            need = true;
          } else {
            need = false;
          }
        }
        if (this.dayFilter.length > 0 && need) {
          if (this.dayFilter.includes(raid.day)) {
            need = true;
          } else {
            need = false;
          }
        }
        if (this.cancelledFilter && need) {
          need = raid.runStatus === "Cancelled";
        }
        if (this.completedFilter && need) {
          need = raid.completed;
        }

        if (
          need &&
          (raid.leader == this.$store.state.user.id ||
            raid.gc == this.$store.state.user.id)
        ) {
          raids.push(raid);
        }
      });
      return raids.sort((a, b) => {
        const dateA = moment(a.date, "YYYY-MM-DD");
        const dateB = moment(b.date, "YYYY-MM-DD");
        const timeA = moment(a.time, "HH:mm");
        const timeB = moment(b.time, "HH:mm");

        if (dateA.isBefore(dateB)) {
          return -1;
        } else if (dateA.isAfter(dateB)) {
          return 1;
        } else {
          return timeA.diff(timeB, "milliseconds");
        }
      });
    },
  },
  mounted: function () {
    this.$store.dispatch("getGoldCollectors");
    this.$store.dispatch("getLeaders");
    this.$store.dispatch("getRaids", {
      doneAndCancelled: true,
      end: moment().tz("Europe/Berlin").add(7, "days").format("YYYY-MM-DD"),
    });
    this.hiddenRaidsData = JSON.parse(
      localStorage.getItem("hiddenRaids") || "[]"
    );
  },
  watch: {
    "$store.state.raids": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
    completedFilter: {
      handler: function (val) {
        if (val) {
          this.cancelledFilter = false;
        }
      },
    },
    cancelledFilter: {
      handler: function (val) {
        if (val) {
          this.completedFilter = false;
        }
      },
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('div',{class:{
      'd-flex': true,
      'align-center': true,
      active: _vm.$props.current === 'shiftbooking&history',
    }},[_c('p',{staticClass:"white--text text-h4 text-center mx-5"},[_vm._v("|")]),_c('p',{staticClass:"white--text text-h4 text-center"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#03a9f4"},attrs:{"to":"/booking/raid/shiftbookandhistory"}},[_vm._v(" Bookings - Last 2 ")])],1)]),_c('div',{class:{
      'd-flex': true,
      'align-center': true,
      active: _vm.$props.current === 'booking&history',
    }},[_c('p',{staticClass:"white--text text-h4 text-center mx-5"},[_vm._v("|")]),_c('p',{staticClass:"white--text text-h4 text-center"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#03a9f4"},attrs:{"to":"/booking/raid/bookandhistory"}},[_vm._v(" Bookings - Raid ")])],1)]),_c('div',{class:{
      'd-flex': true,
      'align-center': true,
      active: _vm.$props.current === 'string',
    }},[_c('p',{staticClass:"white--text text-h4 text-center mx-5"},[_vm._v("|")]),_c('p',{staticClass:"white--text text-h4 text-center"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#03a9f4"},attrs:{"to":"/booking/raid/stringhistory"}},[_vm._v(" Gold History ")])],1)]),_c('div',{class:{
      'd-flex': true,
      'align-center': true,
      active: _vm.$props.current === 'roleOverview',
    }},[_c('p',{staticClass:"white--text text-h4 text-center mx-5"},[_vm._v("|")]),_c('p',{staticClass:"white--text text-h4 text-center"},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"#03a9f4"},attrs:{"to":("/booking/raid/overview/" + (_vm.isManager || _vm.isAdmin ? 'manager' : 'leaderandgc'))}},[_vm._v(" Booking overview ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
import { VTextField } from "vuetify/lib";
import Vue from "vue";
import { formatPotToGold } from "@/shared/formatPotToGold";

export default Vue.extend({
  name: "gold-text-field",
  extends: VTextField,
  data: () => ({
    inputValue: null,
  }),
  props: {
    blurredFormat: {
      type: Function,
      default: (v) => {
        // Format
        if (v && !isNaN(v))
          return formatPotToGold((parseFloat(v) * 1000).toString());
      },
    },
    "persistent-hint": {
      type: Boolean,
      default: true,
    },
    hint: {
      type: String,
      default: "Gold in k. Example 100 or 100.5",
    },
  },
  methods: {
    showValue() {
      if (!this.isFocused) {
        // Store the value before change
        this.inputValue = this.lazyValue;
        this.lazyValue = this.blurredFormat(this.lazyValue);
      } else {
        // Show unformatted value on focus
        this.lazyValue = this.inputValue;
      }
    },
  },
  watch: {
    isFocused() {
      this.showValue();
    },
    value() {
      // Handle v-model updates
      if (!this.isFocused) {
        this.showValue();
      }
    },
  },
  mounted() {
    this.showValue();
  },
});

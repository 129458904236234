<template>
  <v-container class="attendance-frame pa-0 ma-0" style="min-width: 100%">
    <v-tabs
      v-model="tab"
      grow
      background-color="transparent"
      hide-slider
      height="80"
      style="min-width: 100%"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        active-class="attendance-tab-active"
        class="attendance-tab text-md-h5 text-sm-h6 white--text"
        style="width: 20%"
      >
        <v-col>
          <div>
            RAID
            {{
              attendances.raidAttendances &&
              attendances.raidAttendances.length > 0
                ? "(" + attendances.raidAttendances.length + ")"
                : ""
            }}
          </div>
          <div class="white--text">
            {{ accumulate(attendances.raidAttendances) }}
          </div>
        </v-col>
      </v-tab>
      <v-tab
        active-class="attendance-tab-active"
        class="attendance-tab text-md-h5 text-sm-h6 white--text"
        style="width: 20%"
      >
        <v-col>
          <div>
            MIXED
            {{
              attendances.dungeonAttendances &&
              attendances.dungeonAttendances.length > 0
                ? "(" + attendances.dungeonAttendances.length + ")"
                : ""
            }}
          </div>
          <div class="white--text">
            {{ accumulate(attendances.dungeonAttendances) }}
          </div>
        </v-col>
      </v-tab>
      <v-tab
        active-class="attendance-tab-active"
        class="attendance-tab text-md-h5 text-sm-h6 white--text"
        style="width: 20%"
      >
        <v-col>
          <div>
            ADVERTISEMENT
            {{
              attendances.advAttendances &&
              attendances.advAttendances.length > 0
                ? "(" + attendances.advAttendances.length + ")"
                : ""
            }}
          </div>
          <div class="white--text">
            {{ accumulateAdvertisements(attendances.advAttendances) }}
          </div>
        </v-col>
      </v-tab>
      <v-tab
        active-class="attendance-tab-active"
        class="attendance-tab text-md-h5 text-sm-h6 white--text"
        style="width: 20%"
      >
        <v-col>
          <div>
            transactions
            {{
              attendances.transactions && attendances.transactions.length > 0
                ? "(" + attendances.transactions.length + ")"
                : ""
            }}
          </div>
          <div class="white--text">
            {{ accumulateTransactions(attendances.transactions) }}
          </div>
        </v-col>
      </v-tab>
      <v-tab
        active-class="attendance-tab-active"
        class="attendance-tab text-md-h5 text-sm-h6 white--text"
        style="width: 20%"
      >
        <v-col>
          <div>
            missing mails
            {{
              attendances.missingMails && attendances.missingMails.length > 0
                ? "(" + attendances.missingMails.length + ")"
                : ""
            }}
          </div>
          <div class="white--text">
            {{ accumulateTransactions(attendances.missingMails) }}
          </div>
        </v-col>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="width: 100%">
      <v-tab-item>
        <RaidAttendance :raidAttendances="attendances.raidAttendances" />
      </v-tab-item>
      <v-tab-item>
        <DungeonAttendance
          :dungeonAttendances="attendances.dungeonAttendances"
        />
      </v-tab-item>
      <v-tab-item>
        <AdvertisementAttendance :advAttendances="attendances.advAttendances" />
      </v-tab-item>
      <v-tab-item>
        <TransactionAttendance :transactions="attendances.transactions" />
      </v-tab-item>
      <v-tab-item>
        <MissingMailsAttendance :missingMail="attendances.missingMails" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import RaidAttendance from "./attendances/RaidAttendance";
import DungeonAttendance from "./attendances/DungeonAttendance";
import AdvertisementAttendance from "./attendances/AdvertisementAttendance";
import TransactionAttendance from "./attendances/TransactionAttendance";
import MissingMailsAttendance from "./attendances/MissingMailAttendance";
import { formatPotToGold } from "@/shared/formatPotToGold";
import Vue from "vue";
export default Vue.extend({
  name: "Attendances",
  components: {
    TransactionAttendance,
    AdvertisementAttendance,
    DungeonAttendance,
    RaidAttendance,
    MissingMailsAttendance,
  },
  props: ["attendances"],
  data: () => ({
    tab: null,
  }),
  methods: {
    accumulate(attendances) {
      if (attendances && attendances.length > 0) {
        return formatPotToGold(
          attendances
            .reduce((acc, atd) => {
              return acc + parseInt(atd.boosterCut);
            }, 0)
            .toString()
        );
      } else {
        return formatPotToGold("0");
      }
    },
    accumulateAdvertisements(attendances) {
      if (attendances && attendances.length > 0) {
        return formatPotToGold(
          attendances
            .reduce((acc, atd) => {
              return acc + parseInt(atd.yourCut);
            }, 0)
            .toString()
        );
      } else {
        return formatPotToGold("0");
      }
    },
    accumulateTransactions(transactions) {
      if (transactions && transactions.length > 0) {
        return formatPotToGold(
          (
            transactions.reduce((acc, atd) => {
              return acc + parseInt(atd.delta.replace("[,.-]", ""));
            }, 0) * -1
          ).toString()
        );
      } else {
        return formatPotToGold("0");
      }
    },
  },
});
</script>

<style scoped>
.attendance-tab-active {
  background: #6381b4 !important;
}
.attendance-tab {
  border: 1px solid #6381b4;
  background: #6381b440;
}
.attendance-frame {
  border: 1px solid #6381b4;
}
</style>

import { render, staticRenderFns } from "./ShiftAdvertiserOverview.vue?vue&type=template&id=fa517db2&scoped=true&"
import script from "./ShiftAdvertiserOverview.vue?vue&type=script&lang=ts&"
export * from "./ShiftAdvertiserOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa517db2",
  null
  
)

export default component.exports
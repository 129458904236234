













import Vue from "vue";
import ShiftAdvertiserOverviewForm from '@/components/raidmanagement/form/ShiftAdvertiserOverviewForm.vue'

export default Vue.extend({
    name: "ShiftAdvertiserOverview",
    components: { ShiftAdvertiserOverviewForm }
});

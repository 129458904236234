















































































































































































































































import {
  bookingTypeOptions,
  clothClasses,
  dreadfulTokenClasses,
  leatherClasses,
  mailClasses,
  myticTokenClasses,
  plateClasses,
  veneratedTokenClasses,
  WoWClass,
  zenithTokenClasses,
} from "@/shared/constants";
import { Customer, Raid } from "@/interfaces/Raids";
import Vue from "vue";

export default Vue.extend({
  name: "RaidBookingInformationDialog",
  props: {
    dialog: {
      required: true,
      type: Boolean,
    },
    raid: {
      required: true,
      type: Object,
    },
    needLead: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    dialogState: false,
  }),
  mounted() {
    if (this.needLead) {
      this.$store.dispatch("getLeaders");
    }
    Vue.nextTick(() => {
      this.dialogState = this.dialog;
    });
  },
  methods: {
    closeDialog(event) {
      this.$emit("dialogClose", event);
    },
    myticTokenClasses() {
      return myticTokenClasses;
    },
    dreadfulTokenClasses() {
      return dreadfulTokenClasses;
    },
    zenithTokenClasses() {
      return zenithTokenClasses;
    },
    veneratedTokenClasses() {
      return veneratedTokenClasses;
    },
    getClassIcon(fieldClass: string) {
      return require(`@/assets/Icons/${fieldClass.replaceAll(
        " ",
        "_"
      )}_Class.webp`);
    },
  },
  computed: {
    bookingInfo: function () {
      if (this.raid.id) {
        let buyers: Array<Customer> = this.raid.Buyer;
        let backups: Array<Customer> = this.raid.Backup as Array<Customer>;
        let i = 1;
        let merged: Array<Customer> = [...buyers, ...(backups || [])]
          .filter((c: Customer) => {
            return (
              c.customerStatus !== "Cancelled" &&
              c.customerStatus !== "AFK" &&
              c.customerStatus !== "Offline" &&
              c.customerStatus !== "Backup"
            );
          })
          .map((c: Customer) => {
            c.raid = this.raid.id;
            c.order = i;
            i++;
            return c;
          });
        return merged;
      }
      return [];
    },
    clothBookings: function () {
      const bookings = this.bookingInfo as Array<Customer>;
      return bookings.filter((booking: Customer) => {
        return (
          clothClasses.includes(booking.buyerClass as WoWClass) &&
          booking.customerStatus !== "Cancelled" &&
          booking.customerStatus !== "AFK" &&
          booking.customerStatus !== "Offline" &&
          booking.customerStatus !== "Backup"
        );
      });
    },
    plateBookings: function () {
      const bookings = this.bookingInfo as Array<Customer>;
      return bookings.filter((booking: Customer) => {
        return (
          plateClasses.includes(booking.buyerClass as WoWClass) &&
          booking.customerStatus !== "Cancelled" &&
          booking.customerStatus !== "AFK" &&
          booking.customerStatus !== "Offline" &&
          booking.customerStatus !== "Backup"
        );
      });
    },
    leatherBookings: function () {
      const bookings = this.bookingInfo as Array<Customer>;
      return bookings.filter((booking: Customer) => {
        return (
          leatherClasses.includes(booking.buyerClass as WoWClass) &&
          booking.customerStatus !== "Cancelled" &&
          booking.customerStatus !== "AFK" &&
          booking.customerStatus !== "Offline" &&
          booking.customerStatus !== "Backup"
        );
      });
    },
    mailBookings: function () {
      const bookings = this.bookingInfo as Array<Customer>;
      return bookings.filter((booking: Customer) => {
        return (
          mailClasses.includes(booking.buyerClass as WoWClass) &&
          booking.customerStatus !== "Cancelled" &&
          booking.customerStatus !== "AFK" &&
          booking.customerStatus !== "Offline" &&
          booking.customerStatus !== "Backup"
        );
      });
    },
  },
  watch: {
    dialog: {
      handler() {
        this.dialogState = this.dialog;
      },
    },
  },
});

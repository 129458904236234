<template>
  <v-container fill-height fluid>
    <v-form v-model="valid" style="width: 100%" ref="form">
      <v-container>
        <v-row cols="12" justify="center"
          ><div class="text-h2 white--text">Current</div></v-row
        >
        <v-row>
          <v-col md="2" class="hidden-sm-and-down"></v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="currentStart"
                  :rules="dateRules"
                  label="Current Cycle Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="currentStart"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="currentEnd"
                  :rules="dateRules"
                  label="Current Cycle End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="currentEnd"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" class="hidden-sm-and-down"></v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="currentPaymentStart"
                  :rules="dateRules"
                  label="Current Cycle Payment Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="currentPaymentStart"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="currentPaymentEnd"
                  :rules="dateRules"
                  label="Current Cycle Payment End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="currentPaymentEnd"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row cols="12" justify="center"
          ><div class="text-h2 white--text">Old</div></v-row
        >
        <v-row>
          <v-col md="2" class="hidden-sm-and-down"></v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="oldStart"
                  :rules="dateRules"
                  label="Old Cycle Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="oldStart"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="oldEnd"
                  :rules="dateRules"
                  label="Old Cycle End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="oldEnd"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="2" class="hidden-sm-and-down"></v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="oldPaymentStart"
                  :rules="dateRules"
                  label="Old Cycle Payment Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="oldPaymentStart"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              :close-on-content-click="true"
              :nudge-right="$vuetify.breakpoint.smAndDown ? 0 : 40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dark
                  v-model="oldPaymentEnd"
                  :rules="dateRules"
                  label="Old Cycle Payment End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="oldPaymentEnd"></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            :disabled="!valid"
            class="mr-4"
            color="success"
            @click="configureCycle"
          >
            Configure
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import { getDateObjectFromPickerValue } from "@/shared/getDateObjectFromPickerValue";
import Vue from "vue";

export default Vue.extend({
  name: "CycleConfig",
  data: () => ({
    valid: false,
    currentStart: null,
    currentEnd: null,
    currentPaymentStart: null,
    currentPaymentEnd: null,
    oldStart: null,
    oldEnd: null,
    oldPaymentStart: null,
    oldPaymentEnd: null,
    dateRules: [(v) => !!v || "Date is required"],
  }),
  methods: {
    configureCycle() {
      this.$refs.form.validate();
      const cycle = {
        currentStart: getDateObjectFromPickerValue(this.currentStart),
        currentEnd: getDateObjectFromPickerValue(this.currentEnd),
        currentPaymentStart: getDateObjectFromPickerValue(
          this.currentPaymentStart
        ),
        currentPaymentEnd: getDateObjectFromPickerValue(this.currentPaymentEnd),
        oldStart: getDateObjectFromPickerValue(this.oldStart),
        oldEnd: getDateObjectFromPickerValue(this.oldEnd),
        oldPaymentStart: getDateObjectFromPickerValue(this.oldPaymentStart),
        oldPaymentEnd: getDateObjectFromPickerValue(this.oldPaymentEnd),
      };
      this.$emit("configureCycle", cycle);
      this.$refs.form.reset();
    },
  },
});
</script>

<style scoped></style>






















































































import CookieLaw from "vue-cookie-law";
import Vue from "vue";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter, faDiscord, faFacebook } from "@fortawesome/free-brands-svg-icons";

library.add(faTwitter)
library.add(faDiscord)
library.add(faFacebook)
export default Vue.extend({
  name: "AppFooter",
  components: { CookieLaw },
  data: () => ({

  }),
});















import Vue from "vue";
import MythicForm from "@/components/booking/form/MythicForm.vue";

export default Vue.extend({
  name: "Mythic",
  components: { MythicForm },
});


















import Vue from "vue";
import Partners from "../components/boostee/Partners.vue";
import HomeHero from "@/components/boostee/HomeHero.vue";
import OblivionDivider from "@/components/boostee/OblivionDivider.vue";
import Streamers from "@/components/boostee/Streamers.vue";
import About from "@/components/boostee/About.vue";
import Services from "@/components/boostee/Services.vue";

export default Vue.extend({
  name: "Boostee",

  components: {
    Services,
    About,
    OblivionDivider,
    HomeHero,
  },
});















import Vue from "vue";
import ShiftCreationForm from "@/components/raidmanagement/form/ShiftCreationForm.vue";

export default Vue.extend({
  name: "ShiftCreation",
  components: { ShiftCreationForm },
});

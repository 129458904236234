<template>
  <v-card
    class="rounded-lg"
    style="position: relative; background-color: #2f4975"
  >
    <v-img
      style="position: relative"
      :src="require(`@/assets/PVP/pvp.jpg`)"
      min-height="200"
      max-height="200"
    >
      <v-container
        fill-height
        style="
          background-color: rgba(80, 80, 80, 0.6);
          position: absolute;
          width: 100%;
          height: 100%;
        "
      >
        <v-row align="center" justify="center">
          <v-col cols="12" class="white--text text-h3 text-center">PVP</v-col>
        </v-row>
      </v-container>
    </v-img>
    <v-card-text class="white--text">
      <ul>
        <li>2v2</li>
        <li>3v3</li>
        <li>RBG</li>
      </ul>
    </v-card-text>
    <v-container
      fill-height
      fluid
      style="
        position: absolute;
        left: 0px;
        top: 0px;
        background-color: rgba(80, 80, 80, 0.8);
      "
    >
      <v-row align="center" justify="center">
        <v-col class="text-center">
          <v-icon color="white" size="70">mdi-progress-clock</v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "PVPCard",
};
</script>

<style scoped>
ul {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}
</style>













































import Vue from "vue";
// import WinterSpecial from "@/components/boostee/misc/WinterSpecial.vue";

export default Vue.extend({
  name: "HomeHero",
  // components: { WinterSpecial },
  data: () => ({
    mounted: false,
    slideIn: false,
  }),
  mounted() {
    this.mounted = true;
  },
});

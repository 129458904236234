













import Vue from "vue";
import YourRunHistoryOverviewForm from "@/components/booking/form/YourRunHistoryOverviewForm.vue"

export default Vue.extend({
  name: "YourRunHistory",
  components: { YourRunHistoryOverviewForm },
});





















































































































































































































































































































































































































import Vue from "vue";
import { classOptions, rosterClasses } from "@/shared/constants";
import { Signup } from "@/interfaces/Rosters";
import moment from "moment-timezone";

export default Vue.extend({
    name: "RaidRosterRow",
    data: () => ({
        classes: {
        },
        roster: {

        } as any,
        deleteConfirmDialog: false,
        pingConfirmDialog: false,
        picked: [] as any,
        removed: [] as any,
        loading: false,
        defaultState: { },
        hordeChar: '',
        allianceChar: '',
        leaderNote: '',
        voiceChannel: '',
        customText: '',
        spamButtons: {
            'save': false,
            'ping': false,
            'note': false,
            'count': false,
            'urgent': false
        },
        roleOptions: [
            "tank",
            "healer",
            "dps",
        ],
        wantedTank: 2,
        wantedHeal: 2,
        wantedDps: 6,
        urgentText: ""
    }),
    props: {
        rosterData: {
            required: true,
            type: Object
        },
        updateMethod: {
            required: true,
            type: Function
        }
    },
    mounted() {
        if (localStorage.getItem("horde")) {
            this.hordeChar = localStorage.getItem('horde') || ""
        }
        if (localStorage.getItem("alliance")) {
            this.allianceChar = localStorage.getItem('alliance') || ""
        }
        this.roster = this.rosterData
        this.leaderNote = this.roster.leaderNote
        this.defaultState = JSON.parse(JSON.stringify(this.rosterData))
        this.wantedTank = this.roster.wantedTank
        this.wantedHeal = this.roster.wantedHeal
        this.wantedDps = this.roster.wantedDps

        Object.keys(this.spamButtons).forEach(button => {
            localStorage.removeItem(button)
            const data = localStorage.getItem(button)
            if (data) {
                const duration = moment.duration(this.moment(data).add(30, "minute").diff(this.moment()))
                setTimeout(() => {
                    this.spamButtons[button] = true
                    localStorage.removeItem(button)
                }, duration.asMinutes() * 60 * 1000)
            } else {
                this.spamButtons[button] = true
            }
        })
    },
    watch: {
        'rosterData': {
            deep: true,
            handler: function() {
                const parsed = JSON.parse(JSON.stringify(this.rosterData));
                Object.keys(parsed.signups).forEach(boosterId => {
                    parsed.signups[boosterId].forEach((signup, index) => {
                        if (!signup || !signup.role || !signup.class || !(signup.logs || signup.logsLink) || !signup.status) {
                            delete parsed.signups[boosterId][index]
                        }
                    })
                })
                this.roster = parsed;
            }
        }
    },
    methods: {
        classOptions() {
            return classOptions
        },
        replaceUrls(text: string) {
            const urlRegex = /(https?:\/\/[^\s]+)/g;
            if (!text) return "";
            return text.replace(urlRegex, function(url) {
                return '&nbsp;<a href="' + url + '">Logs</a>';
            })
        },
        spamProtection(button) {
            const timers = {
                'save': 30000,
                'ping': 30000,
                'note': 30000,
                'count': 30000,
                'urgent': (1000 * 60 * 30)
            }

            localStorage.setItem(button, (new Date()).toLocaleString())
            setTimeout(() => {
                this.spamButtons[button] = true;
                localStorage.removeItem(button)
            }, timers[button])
        },
        getClassCount(rosterClass) {
            let count = 0;
            this.tanks.forEach((chars) => {
                count += chars.filter((booster: any) => booster.classes.replaceAll(" ", "").toLowerCase() === rosterClass.replaceAll(" ", "").toLowerCase()).length
            })
            this.heals.forEach((chars) => {
                count += chars.filter((booster: any) => booster.classes.replaceAll(" ", "").toLowerCase() === rosterClass.replaceAll(" ", "").toLowerCase()).length
            })
            this.dps.forEach((chars) => {
                count += chars.filter((booster: any) => booster.classes.replaceAll(" ", "").toLowerCase() === rosterClass.replaceAll(" ", "").toLowerCase()).length
            })
            return count
        },
        deleteRoster(roster) {
            this.$store.dispatch("deleteRoster", {
                roster: roster
            }).then(() => {
                this.deleteConfirmDialog = false;
                this.updateMethod();
            })
        },
        pingInvites() {
            this.spamButtons.ping = false;
            localStorage.setItem("horde", this.hordeChar)
            localStorage.setItem("alliance", this.allianceChar)
            this.$store.dispatch("pingInvites", {
                messageId: this.roster.message,
                hordeChar: this.hordeChar,
                allianceChar: this.allianceChar,
                customText: this.customText,
                voiceChannel: this.voiceChannel
            })
            this.spamProtection('ping')
        },
        setRoleCount() {
            this.spamButtons.count = false;
            this.$store.dispatch("setRoleCount", {
                message: this.roster.message,
                tank: this.wantedTank,
                heal: this.wantedHeal,
                dps: this.wantedDps
            })
            this.spamProtection('count')
        },
        editNote() {
            this.spamButtons.note = false;
            this.$store.dispatch("editNote", {
                messageId: this.roster.message,
                leaderNote: this.leaderNote
            })
            this.spamProtection('note')
        },
        getPickedCount(role) {
            let count = 0;
            this[role].forEach((b) => count += b.filter((c) => c.selected).length)
            return count;
        },
        urgentPing() {
            this.$store.dispatch("urgentPing", {
                raidId: this.roster.raid.id,
                message: this.urgentText
            }).then(() => {
                this.spamProtection('urgent')
            })
        },
        moment(date: any = null) {
            if (date == null) {
                date = new Date();
            }
            return moment(date).tz("Europe/Budapest")
        },
        tooEarly(raid, before) {
            const raidTime = this.moment(`${raid.date} ${raid.time}`).local();
            return raidTime.subtract(before, "hours").isAfter(this.moment())
        },
        sendNotifications() {
            this.loading = true;
            this.spamButtons.save = false;
            const promises: Array<Promise<void>> = []
            let delay = 0;
            this.picked.forEach((b) => {
                promises.push(new Promise<void>((resolve, reject) => {
                    setTimeout(() => {
                        this.$store.dispatch("pickBooster", {
                            role: b.role,
                            boosterClass: b.class,
                            state: b.state,
                            booster: b.id,
                            raidId: this.roster.raid.id,
                        }).then(() => {
                            resolve()
                        }).catch(() => {
                            reject();
                        })
                    }, delay)
                }))
                delay += 2000;
            })

            this.removed.forEach((b) => {
                promises.push(new Promise<void>((resolve, reject) => {
                    setTimeout(() => {
                        this.$store.dispatch("unPickBooster", {
                            booster: b.id,
                            raidId: this.roster.raid.id,
                        }).then(() => {
                            resolve()
                        }).catch(() => {
                            reject();
                        })
                    }, delay)
                }))
                delay += 2000;
            })


            Promise.all(promises).then(() => {
                this.updateMethod();

                this.picked = [];
                this.removed = [];
                this.loading = false;

                this.spamProtection('save')
            })
        },
        getClassIcon(fieldClass: string) {
            if (!fieldClass) return null
            return require(`@/assets/Icons/${fieldClass.replaceAll(" ", "_")}_Class.webp`);
        },
        changeBoosterRole(booster) {
            this.$store.dispatch("setBoosterRole", {
                raidId: this.roster.raid.id,
                booster: booster.id,
                charClass: booster.classes.replaceAll(' ','').toLowerCase(),
                role: booster.role === "healer" ? 'heal' : booster.role,
            }).then(() => {
                this.updateMethod()
            })
        },
        changeBoosterStatus(booster) {
            this.$store.dispatch("setBoosterStatus", {
                raidId: this.roster.raid.id,
                booster: booster.id,
                charClass: booster.classes.replaceAll(' ','').toLowerCase(),
                status: booster.state,
            }).then(() => {
                this.updateMethod()
            })
        },
        changeBoosterClass(booster) {
            this.$store.dispatch("setBoosterClass", {
                raidId: this.roster.raid.id,
                booster: booster.id,
                oldClass: booster.rawClasses,
                newClass: booster.classes.replaceAll(' ','').toLowerCase()
            }).then(() => {
                this.updateMethod()
            })
        },
        pickUnpick(booster){
            Vue.set(booster,'selected', booster.selected)
            if (this.roster[booster.role].some(b => b.id === booster.id)) {
                this.roster[booster.role] = this.roster[booster.role].filter((b) => {
                    if (b.id === booster.id) {
                        if (this.defaultState[booster.role].some(b => b.id === booster.id)) {
                            this.removed.push({
                                class: booster.rawClasses,
                                id: booster.id,
                                role: booster.role,
                                state: booster.state
                            })
                        }
                        this.picked = this.picked.filter(c => c.id !== booster.id)
                        return false;
                    }
                    return true;
                })
            } else {
                this.roster[booster.role].push({
                    class: booster.rawClasses,
                    id: booster.id,
                    role: booster.role,
                    state: booster.state
                })
                if (!this.defaultState[booster.role].some(b => b.id === booster.id)) {
                    this.picked.push({
                        class: booster.rawClasses,
                        id: booster.id,
                        role: booster.role,
                        state: booster.state
                    });
                }
                this.removed = this.removed.filter(c => !(c.id === booster.id && booster.class === c.class && booster.role === c.role))
            }
        },
    },
    computed: {
        isManager() {
            return this.$store.state.roles.some(role => role === 'RAIDMANAGER' || role === 'SUPERADMIN');
        },
        rosterClasses() {
            return [... new Set(Object.values(rosterClasses))]
        },
        allBooster: function(): Array<{id: string, 'class': string, role: string}> {
            const boosters: Array<{id: string, 'class': string, role: string}> = [];
            const roster = this.roster;
            roster.tank.forEach((booster: {id: string, 'class': string, role: string}) => {
                if (!boosters.some(b => b.id === booster.id)){
                    boosters.push(booster);
                }
            })
            roster.healer.forEach((booster: {id: string, 'class': string, role: string}) => {
                if (!boosters.some(b => b.id === booster.id)){
                    boosters.push(booster);
                }
            })
            roster.dps.forEach((booster: {id: string, 'class': string, role: string}) => {
                if (!boosters.some(b => b.id === booster.id)){
                    boosters.push(booster);
                }
            })
            return boosters;
        },
        tanks: function(): Array<Array<Signup>> {
            const returnData: Array<Array<Signup>> = [];
            const signups = this.roster.signups;
            Object.keys(this.roster.signups).forEach((userId) => {
                const userData: Array<Signup> = [];
                Object.values(signups[userId]).forEach((signup: any) => {
                    if (signup.role === "tank") {
                        const selected = this.allBooster.some(b => b.id === userId && b.role === 'tank' && b.class.toLowerCase() === signup.class.toLowerCase());
                        if (!userData.some(ud => ud.id === userId && ud.classes === rosterClasses[signup.class.toLowerCase() && ud.role === 'healer'])) {
                            userData.push({
                                "id": userId,
                                "name": signup.displayName,
                                "classes": rosterClasses[signup.class.toLowerCase()],
                                "rawClasses": signup.class.toLowerCase(),
                                "state": (signup.status || ""),
                                "logs": signup.logsLink ? signup.note : signup.logs,
                                "role": signup.role,
                                "selected": selected,
                                "logsLink": signup.logsLink,
                                "median": signup.median,
                                "best": signup.best,
                                "kills": signup.kills,
                                "itemLvl": signup.ilvl,
                            });
                        }
                    }
                })
                if (userData.length > 0)
                    returnData.push(userData);
            });
            return returnData;
        },
        heals: function(): Array<Array<Signup>> {
            const returnData: Array<Array<Signup>> = [];
            const signups = this.roster.signups;
            Object.keys(this.roster.signups).forEach((userId) => {
                const userData: Array<Signup> = [];
                Object.values(signups[userId]).forEach((signup: any) => {
                    if (signup.role === "heal" || signup.role === "healer") {
                        const selected = this.allBooster.some(b => b.id === userId && b.role === 'healer' && b.class.toLowerCase() === signup.class.toLowerCase());
                        if (!userData.some(ud => ud.id === userId && ud.classes === rosterClasses[signup.class.toLowerCase()] && ud.role === 'healer')) {
                            userData.push({
                                "id": userId,
                                "name": signup.displayName,
                                "classes": rosterClasses[signup.class.toLowerCase()],
                                "rawClasses": signup.class.toLowerCase(),
                                "state": (signup.status || ""),
                                "logs": signup.logsLink ? signup.note : signup.logs,
                                "role": 'healer',
                                "selected": selected,
                                "logsLink": signup.logsLink,
                                "median": signup.median,
                                "best": signup.best,
                                "kills": signup.kills,
                                "itemLvl": signup.ilvl,
                            });
                        }
                    }
                })
                if (userData.length > 0)
                    returnData.push(userData);
            });

            return returnData;
        },
        dps: function(): Array<Array<Signup>> {
            const returnData: Array<Array<Signup>> = [];
            const signups = this.roster.signups;
            Object.keys(this.roster.signups).forEach((userId) => {
                const userData: Array<Signup> = [];
                Object.values(signups[userId]).forEach((signup: any) => {
                    if (signup.role === "dps") {
                        const selected = this.allBooster.some(b => b.id === userId && b.role === 'dps' && b.class.toLowerCase() === signup.class.toLowerCase());
                        if (!userData.some(ud => ud.id === userId && ud.classes === rosterClasses[signup.class.toLowerCase() && ud.role === 'healer'])) {
                            userData.push({
                                "id": userId,
                                "name": signup.displayName,
                                "classes": rosterClasses[signup.class.toLowerCase()],
                                "rawClasses": signup.class.toLowerCase(),
                                "state": (signup.status || ""),
                                "role": signup.role,
                                "logs": signup.logsLink ? signup.note : signup.logs,
                                "selected": selected,
                                "logsLink": signup.logsLink,
                                "median": signup.median,
                                "best": signup.best,
                                "kills": signup.kills,
                                "itemLvl": signup.ilvl,
                            });
                        }
                    }
                })
                if (userData.length > 0)
                    returnData.push(userData);
            });

            return returnData;
        }
    }
});















import RaidScheduleForm from "@/components/raidmanagement/form/RaidScheduleForm.vue";
import Vue from "vue";

export default Vue.extend({
    name: "RaidSchedule",
    components: { RaidScheduleForm }
});

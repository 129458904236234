












import Vue from "vue";
import RaidCollectionsForm from "@/components/raidmanagement/form/RaidCollectionsForm.vue";

export default Vue.extend({
    name: "RaidSchedule",
    components: { RaidCollectionsForm }
});















import RaidRecurringRunsForm from "@/components/raidmanagement/form/RaidRecurringRunsForm.vue";
import Vue from "vue";

export default Vue.extend({
  name: "RaidRecurringRuns",
  components: { RaidRecurringRunsForm },
});

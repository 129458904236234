













import Vue from "vue";
import ShiftCompletedCancelledOverviewList from "@/components/raidmanagement/ShiftCompletedCancelledOverviewList.vue";

export default Vue.extend({
    name: "ShiftCompletedCancelledOverview",
    components: { ShiftCompletedCancelledOverviewList }
});

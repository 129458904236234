












































































































































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { Customer, Raid } from "@/interfaces/Raids";
import RaidBookingMenu from "@/components/booking/RaidBookingMenu.vue";
import { mailedOptions } from "@/shared/constants";
import moment from "moment/moment";

export default Vue.extend({
    name: "GoldOverviewForm",
    components: { RaidBookingMenu },
    data: () => ({
        valid: false,
        runs: [],
        paymentRealm: null,
        collector: null,
        mailed: null,
        runId: null,
        bookingId: null,
        loading: false,
        snackbar: false,
        snackbarText: "Should not show",
        snackbarColor: "red",
        timeout: 2000,
        textRules: [
            (v: string) => !!v || "Field is required",
            (v: string) =>
                (!!v && v.replace(/\s/g, "").length > 1) ||
                "Name must be at least 1 non-whitespace character"
        ],
        required: [(v: any) => !!v || "Field is required"],
    }),
    methods: {
        goBack(e) {
            e.preventDefault();
            this.$router.go(-1)
        },
        async loadRuns() {
            this.runs = this.$store.state.raids;
        },
        snackbarToggle(snackbarData: SnackbarData) {
            this.snackbarText = snackbarData.message;
            this.snackbarColor = snackbarData.color;
            this.snackbar = true;
        }
    },
    computed: {
        mailedOptions() {
            return mailedOptions
        },
        history: function() {
            const bookings: Array<Customer> = [];
            this.runs.forEach((raid: Raid) => {
                bookings.push(...raid.Buyer.filter((booking: Customer) =>{
                    let filtered = true;
                    if(this.paymentRealm !== null && filtered){
                        if(booking.paymentRealm === this.paymentRealm){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    if(this.collector !== null && filtered){
                        if(booking.goldWith === this.collector){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    if(this.mailed !== null && filtered){
                        if((this.mailed as any).value){
                            if(booking.mailed){
                                filtered = true;
                            }
                            else{
                                filtered = false;
                            }
                        } else{
                            if(!booking.mailed){
                                filtered = true;
                            }
                            else{
                                filtered = false;
                            }
                        }

                    }

                    if(this.runId !== null && filtered){
                        if((booking.raid as Raid).id === this.runId){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    if(this.bookingId !== null && filtered){
                        if(booking.id === this.bookingId){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    return booking.paid && booking.customerStatus === "In group" && filtered;
                }));
                bookings.push(...(raid.Backup || []).filter((booking: Customer) =>{
                    let filtered = true;
                    if(this.paymentRealm !== null && filtered){
                        if(booking.paymentRealm === this.paymentRealm){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    if(this.collector !== null && filtered){
                        if(booking.goldWith === this.collector){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    if(this.mailed !== null && filtered){
                        if((this.mailed as any).value){
                            if(booking.mailed){
                                filtered = true;
                            }
                            else{
                                filtered = false;
                            }
                        } else{
                            if(!booking.mailed){
                                filtered = true;
                            }
                            else{
                                filtered = false;
                            }
                        }

                    }

                    if(this.runId !== null && filtered){
                        if((booking.raid as Raid).id === this.runId){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    if(this.bookingId !== null && filtered){
                        if(booking.id === this.bookingId){
                            filtered = true;
                        }
                        else{
                            filtered = false;
                        }
                    }

                    return booking.paid && booking.customerStatus === "In group" && filtered;
                }));
                bookings.map((c: Customer) => {
                    c.raid = raid;
                    return c;
                })
            })
            return bookings;
        },
        paymentRealmOptions: function() {
            const  bookings: Array<Customer> = this.history;
            return bookings.map((booking: Customer) => {
                return booking.paymentRealm;
            });
        },
        collectorOptions: function() {
            const  bookings: Array<Customer> = this.history;
            return bookings.map((booking: Customer) => {
                return booking.goldWith;
            });
        },
        runIdsOptions: function() {
            const  bookings: Array<Customer> = this.history;
            return bookings.map((booking: Customer) => {
                return (booking.raid as Raid).id;
            });
        }
    },
    mounted: function() {
        this.$store.dispatch("getRaids", {
            start: moment().tz("Europe/Berlin").subtract(7, "day").format("YYYY-MM-DD")
        });
    },
    watch: {
        '$store.state.raids': {
            deep: true,
            handler: function() {
                this.loadRuns();
            }
        }
    }
});

<template>
  <v-col cols="12">
    <v-row
      align="center"
      justify="center"
      style="border-bottom: 1px solid #6381b4"
    >
      <v-col md="1" class="text-center">
        <img
          v-if="attendance.faction"
          width="40"
          height="55"
          :src="getFactionIcon(attendance.faction.toLowerCase())"
        />
      </v-col>
      <v-col md="1">
        <div class="white--text text-sm-body-1 text-center">
          {{ attendance.runId }}
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ attendance.date }}
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ attendance.typeOfBoost }}
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ formatGold(attendance.pot) }}
        </div>
        <div class="grey--text text--lighten-1 text-sm-subtitle-1 text-center">
          Pot
        </div>
      </v-col>
      <v-col md="1">
        <div class="white--text text-sm-body-1 text-center">
          {{ attendance.numberOfBoosters }}
        </div>
        <div class="grey--text text--lighten-1 text-sm-subtitle-1 text-center">
          #Boosters
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ formatGold(attendance.boosterCut) }}
        </div>
        <div class="grey--text text--lighten-1 text-sm-subtitle-1 text-center">
          Your Cut
        </div>
      </v-col>
      <v-col md="1">
        <v-row align="center" justify="center">
          <a :href="attendance.logs" target="_blank">
            <v-avatar size="50">
              <v-img
                contain
                :src="require('@/assets/Logo/warcraftlogs.png')"
              ></v-img>
            </v-avatar>
          </a>
        </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { formatPotToGold } from "@/shared/formatPotToGold";
import Vue from "vue";

export default Vue.extend({
  name: "RaidRow",
  props: ["attendance"],
  methods: {
    formatGold(value) {
      return formatPotToGold(value.toString());
    },
    getFactionIcon(item) {
      if (item === "horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
  },
});
</script>

<style scoped></style>

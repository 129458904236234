















































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "PrivacyForm",
  components: { },
  data: () => ({

  }),
  mounted () {
    window.scrollTo(0, 0)
  }
});

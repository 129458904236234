var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"position":"relative"}},[_c('v-form',{ref:"form",staticStyle:{"width":"100%"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('p',{staticClass:"white--text text-h4 pr-3"},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/booking"}},[_c('v-icon',{attrs:{"color":"white","size":"40"}},[_vm._v("mdi-arrow-left-thick")])],1),_vm._v(" Legacy - Booking Information ")],1)])],1),_c('v-divider',{staticClass:"white"}),_c('v-row',{staticClass:"pt-6"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","dark":"","label":"Note","persistent-hint":"","hint":"Needs to contain the Discord or B-net","placeholder":"Example: D: Name#0000 | Bnet: Name#0000","outlined":""},model:{value:(_vm.note),callback:function ($$v) {_vm.note=$$v},expression:"note"}})],1)],1),_c('v-row',{staticClass:"pt-6"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-select',{attrs:{"dense":"","single":"","dark":"","items":_vm.factions,"outlined":"","label":"Faction","rules":_vm.required},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"width":"15","height":"20","src":_vm.getFactionIcon(item)}}),_vm._v(" "+_vm._s(item)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"width":"15","height":"20","src":_vm.getFactionIcon(item)}}),_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.faction),callback:function ($$v) {_vm.faction=$$v},expression:"faction"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","dark":"","rules":_vm.textRules,"label":"Charactername-Realm","placeholder":"Example: Character-Realm","outlined":"","clearable":""},model:{value:(_vm.buyer),callback:function ($$v) {_vm.buyer=$$v},expression:"buyer"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"dense":"","dark":"","rules":_vm.textRules,"label":"Type Of Service","outlined":"","clearable":"","multiple":""},model:{value:(_vm.boostTitle),callback:function ($$v) {_vm.boostTitle=$$v},expression:"boostTitle"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-select',{attrs:{"dense":"","single":"","dark":"","items":_vm.advCutOptions,"outlined":"","label":"FeeOption","rules":_vm.required},model:{value:(_vm.advCut),callback:function ($$v) {_vm.advCut=$$v},expression:"advCut"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('gold-text-field',{attrs:{"dense":"","outlined":"","label":"Price","dark":"","clearable":""},model:{value:(_vm.pot),callback:function ($$v) {_vm.pot=$$v},expression:"pot"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-combobox',{attrs:{"dense":"","single":"","dark":"","items":_vm.paymentRealmOptions,"outlined":"","label":"Payment Realm","rules":_vm.paymentRealmRule},model:{value:(_vm.paymentRealm),callback:function ($$v) {_vm.paymentRealm=$$v},expression:"paymentRealm"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-select',{attrs:{"dense":"","single":"","dark":"","items":_vm.factions,"outlined":"","label":"Payment Faction","rules":_vm.required},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"width":"15","height":"20","src":_vm.getFactionIcon(item)}}),_vm._v(" "+_vm._s(item)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mr-2",attrs:{"width":"15","height":"20","src":_vm.getFactionIcon(item)}}),_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.paymentFaction),callback:function ($$v) {_vm.paymentFaction=$$v},expression:"paymentFaction"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-select',{attrs:{"dense":"","single":"","dark":"","items":_vm.classOptions,"outlined":"","label":"Class","rules":_vm.required},model:{value:(_vm.classSelected),callback:function ($$v) {_vm.classSelected=$$v},expression:"classSelected"}})],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('SummaryOverlay',{attrs:{"values":_vm.computeSummaryValues,"valid":!(_vm.valid && _vm.pot && !isNaN(_vm.pot))},on:{"closeOverlay":_vm.bookCustomer}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12","md":"6"}},[_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.resetForm}},[_vm._v("Reset Form")])],1)],1)],1),_c('v-snackbar',{attrs:{"dark":"","centered":"","timeout":_vm.timeout,"color":_vm.snackbarColor,"min-height":"6vw","min-width":"35vw","content-class":"text-h5 pl-5"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"mr-5",on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" Close ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackbarText)+" ")]),(_vm.loading)?_c('div',{staticStyle:{"width":"100%","height":"100%","top":"0","left":"0","background-color":"rgba(80, 80, 80, 0.6)","position":"absolute","flex-direction":"row"}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"justify":"center","align":"center"}},[_c('v-progress-circular',{staticClass:"justify-center align-content-center align-center",attrs:{"size":50,"color":"primary","indeterminate":""}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }














import Vue from "vue";
import RaidCreationForm from "@/components/raidmanagement/form/RaidCreationForm.vue";

export default Vue.extend({
  name: "RaidCreation",
  components: { RaidCreationForm },
});















import RaidOverviewForm from "@/components/booking/form/RaidOverviewForm.vue";
import Vue from "vue";

export default Vue.extend({
  name: "RaidOverview",
  components: { RaidOverviewForm },
});

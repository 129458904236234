
































import Vue from "vue";
import PointHistory from "@/components/loyaltysystem/PointHistory.vue";
import PurchaseHistory from "@/components/loyaltysystem/PurchaseHistory.vue";
export default Vue.extend({
  name: "LoyaltySystemHistory",
  components: { PointHistory, PurchaseHistory },
  props: ["pointHistory", "purchaseHistory"],
  data: () => ({
    tab: null,
  }),
});

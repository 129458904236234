














































import Vue from "vue";
import moment from "moment-timezone";
import { GoldCollector, Leader, Raid } from "@/interfaces/Raids";
import { orderStatusOptions } from "@/shared/constants";

export default Vue.extend({
  name: "AdminOrderViewItem",
  components: {},
  data: () => ({
    orderStatusOptions: orderStatusOptions,
  }),
  methods: {
    editOrderStatus(entry: any, item: any, index: number) {
      entry.items[index].status = item.status;
      const purchaseHistoryEntry = {
        ...entry,
        items: entry.items,
      };
      this.$store.dispatch("updateOrderStatus", purchaseHistoryEntry);
    },
    formatDate(date: string, format: string): string {
      return moment(date).format(format);
    },
  },
  computed: {},
  props: {
    historyItem: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
});











































































import { Vue, Component } from "vue-property-decorator";
import { SnackbarData } from "@/interfaces/SnackbarData";

export default Vue.extend({
    name: "LogsView",
    components: {  },
    data: () => ({
        raidId: "",
        page: 1,
        logs: [],
        snackbar: false,
        snackbarText: "Should not show",
        snackbarColor: "red",
        timeout: 2000,
    }),
    methods: {
        getDataParam: function(log, param) {
            const data = JSON.parse(log);

            return data[param] || "Not present";
        },
        copyJSON: function(log) {
            navigator.clipboard.writeText(JSON.stringify(log))
            this.snackbarToggle({
                message: "Log copied!",
                color: "blue",
            });
        },
        snackbarToggle(snackbarData: SnackbarData) {
            this.snackbarText = snackbarData.message;
            this.snackbarColor = snackbarData.color;
            this.snackbar = true;
        },
        paginate: function(count) {
            this.page += count
        }
    },
    watch: {
        raidId: function() {
            this.page = 1;
            if (this.raidId.length > 10) {
                this.$store.dispatch("getLogs", {
                    raidId: this.raidId,
                    page: this.page
                }).then((request) => {
                    this.logs = request.data
                    console.log(this.logs);
                })
            }
        },
        page: function() {
            if (this.raidId.length > 10 && this.page > 0) {
                this.$store.dispatch("getLogs", {
                    raidId: this.raidId,
                    page: this.page
                }).then((request) => {
                    this.logs = request.data
                    console.log(this.logs);
                })
            }
        }
    }
});

<template>
  <v-container fill-height fluid>
    <v-form v-model="valid" style="width: 100%" ref="form">
      <v-container>
        <v-row cols="12" justify="center"
          ><div class="text-h3 white--text">
            Manual add or remove loyality points
          </div></v-row
        >
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              dense
              dark
              v-model="discordId"
              label="Target discord id"
              required
              persistent-hint
              hint="Required"
              placeholder="Enter the target discord id"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              dense
              dark
              v-model="amount"
              label="Amount of points to award"
              required
              persistent-hint
              hint="Required"
              placeholder="If you want to deduct points put - infront of the number"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              dense
              dark
              v-model="reason"
              label="Reason"
              required
              persistent-hint
              hint="Required"
              placeholder="Reason for the award or deduct of loyalty points"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn
            :disabled="!valid"
            class="mr-4"
            color="success"
            @click="createManualLoyaltyRequest"
          >
            Submit
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "LoyaltySystem",
  data: () => ({
    discordId: "",
    amount: "",
    reason: "",
  }),
  methods: {
    createManualLoyaltyRequest() {
      const loyaltyRequest = {
        discordId: this.discordId,
        amount: this.amount,
        reason: this.reason,
      };
      this.$emit("createManualLoyaltyRequest", loyaltyRequest);
    },
  },
});
</script>

<style scoped></style>

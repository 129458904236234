













import Vue from "vue";
import GoldOverviewForm from "@/components/raidmanagement/form/GoldOverviewForm.vue"

export default Vue.extend({
  name: "GoldOverview",
  components: { GoldOverviewForm },
});






















import Vue from "vue";
import ShiftRoleOverviewList from "@/components/raidmanagement/ShiftRoleOverviewList.vue";

export default Vue.extend({
  name: "ShiftRoleOverview",
  components: { ShiftRoleOverviewList },
});












import AppNavigation from "@/components/AppNavigation.vue";
import AppFooter from "@/components/AppFooter.vue";
import Vue from "vue";

export default Vue.extend({
    name: "App",
    components: { AppFooter, AppNavigation },
    // watch: {
    //     $route: {
    //         deep: true,
    //         handler: function() {
    //             this.$store.commit("CLEAR_API");
    //         }
    //     }
    // }
});















import ShiftScheduleForm from "@/components/raidmanagement/form/ShiftScheduleForm.vue";
import Vue from "vue";

export default Vue.extend({
    name: "ShiftSchedule",
    components: { ShiftScheduleForm }
});

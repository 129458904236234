






















































import Balances from "@/components/booster/Balances.vue";
import Attendances from "@/components/booster/Attendances.vue";
import Vue from "vue";
import ViewAsBooster from "@/components/booster/ViewAsBooster.vue";
import { formatDateRange } from "@/shared/formatDateRange";

export default Vue.extend({
  name: "Booster",
  components: { ViewAsBooster, Attendances, Balances },
  data: () => ({
    balances: {} as any,
    attendances: {} as any,
    currentSelected: true,
    currentRegionEu: true,
    loaded: false,
  }),
  async mounted() {
    await this.$store.dispatch("getCycleData");
    this.loaded = true;
    this.handleLoadedCycleData();
  },
  methods: {
    updateCurrentSelected(value: boolean) {
      this.currentSelected = value;
    },
    getCorrectAttendances() {
      if (this.currentSelected) {
        return this.attendances.eu.current;
      } else {
        return this.attendances.eu.old;
      }
    },
    getCorrectBalance() {
      if (this.currentSelected) {
        return this.balances.eu;
      } else {
        return this.balances.eu;
      }
    },
    async loadAsBooster(discordId: string) {
      this.loaded = false;
      await this.$store.dispatch("getCycleDataAsAdmin", discordId);
      this.loaded = true;
      this.currentSelected = true;
      this.handleLoadedCycleData();
    },
    handleLoadedCycleData() {
      let data = this.$store.state.cycleData;
      if (data) {
        this.balances = {
          eu: {
            current: [
              { label: "Payment Char", value: data.paymentChar },
            ].concat(
              {
                label: "Total Balance",
                value: data.eu.current.totalBalance + "g" || "0g",
              },
              {
                label: "Total Paid",
                value: data.eu.current.totalPaid + "g" || "0g",
              },
              {
                label: "Total Owed",
                value: data.eu.current.totalOwed + "g" || "0g",
              },
              {
                label: "Cycle Duration",
                value: formatDateRange(
                  new Date(data.eu.current.start),
                  new Date(data.eu.current.end)
                ),
              },
              {
                label: "Payment Duration",
                value: formatDateRange(
                  new Date(data.eu.current.paymentStart),
                  new Date(data.eu.current.paymentEnd)
                ),
              }
            ),
            old: [{ label: "Payment Char", value: data.paymentChar }].concat(
              {
                label: "Total Balance",
                value: data.eu.old.totalBalance + "g" || "0g",
              },
              {
                label: "Total Paid",
                value: data.eu.old.totalPaid + "g" || "0g",
              },
              {
                label: "Total Owed",
                value: data.eu.old.totalOwed + "g" || "0g",
              },
              {
                label: "Cycle Duration",
                value: formatDateRange(
                  new Date(data.eu.old.start),
                  new Date(data.eu.old.end)
                ),
              },
              {
                label: "Payment Duration",
                value: formatDateRange(
                  new Date(data.eu.old.paymentStart),
                  new Date(data.eu.old.paymentEnd)
                ),
              }
            ),
          },
        };
        this.attendances = {
          eu: {
            current: {
              raidAttendances: data.eu?.current?.raidAttendance || [],
              dungeonAttendances: data.eu?.current?.dungeonAttendance || [],
              advAttendances: data.eu?.current?.advAttendance || [],
              transactions: data.eu?.current.transactions || [],
              missingMails: data.eu?.current.missingMail || [],
            },
            old: {
              raidAttendances: data.eu?.old?.raidAttendance || [],
              dungeonAttendances: data.eu?.old?.dungeonAttendance || [],
              advAttendances: data.eu?.old?.advAttendance || [],
              transactions: data.eu?.old.transactions || [],
              missingMails: data.eu?.old.missingMail || [],
            },
          },
        };
      }
    },
  },
});


























































import Vue from "vue";
import NameChangeLogs from "@/components/logging/NameChangeLogs.vue";

export default Vue.extend({
  name: "LogViewer",
  components: {
    NameChangeLogs,
  },
  data: () => ({
    tab: null,
  }),
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$store.state.isAuthenticated &&
    _vm.$store.state.roles.some(
      function (role) { return role === 'RAIDMANAGER' ||
        role === 'ADMIN' ||
        role === 'ADMIN' ||
        role === 'GCMANAGER' ||
        role === 'WEBACCESS'; }
    )
  )?_c('div',{staticClass:"fill-height",staticStyle:{"background":"linear-gradient(#000923, #181819)","padding":"90px 30px 30px"}},[_c('completed-cancelled-overview-list')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }














import Vue from "vue";
import MountsForm from "@/components/booking/form/MountsForm.vue";

export default Vue.extend({
  name: "Mounts",
  components: { MountsForm },
});


























































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import {
  OrderItemForAdminView,
  PurchaseHistoryEntry,
  PurchaseHistoryItem,
} from "@/interfaces/LoyaltySystem";
import { orderStatusOptions } from "@/shared/constants";
import moment from "moment-timezone";
import AdminOrderViewItem from "@/components/loyaltysystem/AdminOrderViewItem.vue";

export default Vue.extend({
  name: "AdminOrderViewForm",
  components: { AdminOrderViewItem },
  data: () => ({
    runs: [] as Array<OrderItemForAdminView>,
    valid: false,
    loading: false,
    snackbar: false,
    orderStatusOptions: orderStatusOptions,
    statusOptionsFilter: "",
    orderFilter: "",
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    page: 1,
    limit: 7,
  }),
  methods: {
    step(step: number, fromInput: boolean) {
      this.page = fromInput
        ? parseInt(step + "")
        : this.page + parseInt(step + "");
      if (this.page < 1) this.page = 1;
      if (this.page > this.maxPage) this.page = this.maxPage;
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    async loadRuns() {
      this.runs = this.$store.state.ordersForAdmin;
    },
  },
  computed: {
    pagedRaids: function (): Array<OrderItemForAdminView> {
      const runs = this.filteredOrders;
      const start = (this.page - 1) * this.limit;
      return runs.length > this.limit
        ? runs.slice(start, start + this.limit)
        : runs;
    },
    filteredOrders: function (): Array<OrderItemForAdminView> {
      const orders: Array<OrderItemForAdminView> = [];
      this.runs.forEach((raid: OrderItemForAdminView) => {
        let filtered = true;

        if (this.orderFilter.length > 0 && filtered) {
          let temp = false;
          raid.purchaseHistory.forEach((order: PurchaseHistoryEntry) => {
            if (order.orderID.includes(this.orderFilter)) {
              temp = true;
            }
          });
          if (temp) {
            filtered = true;
          } else {
            filtered = false;
          }
        }

        if (this.statusOptionsFilter.length > 0 && filtered) {
          let itemStatusRelevant = false;
          raid.purchaseHistory.forEach((purchaseItem) => {
            purchaseItem.items.forEach((item: PurchaseHistoryItem) => {
              if (this.statusOptionsFilter.includes(item.status))
                itemStatusRelevant = true;
            });
          });

          if (itemStatusRelevant) {
            filtered = true;
          } else {
            filtered = false;
          }
        }

        if (filtered) {
          orders.push(raid);
        }
      });
      return orders;
    },
    maxPage: function () {
      const raids: Array<OrderItemForAdminView> = this.filteredOrders;
      return Math.max(1, Math.ceil(raids.length / this.limit));
    },
  },
  beforeMount: function () {
    this.$store.dispatch("getOrdersForAdmin");
  },
  watch: {
    "$store.state.ordersForAdmin": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
    orderFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    statusOptionsFilter: {
      handler: function () {
        this.page = 1;
      },
    },
  },
});




































































































import { library } from '@fortawesome/fontawesome-svg-core';
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

library.add(faDiscord)
export default {
  name: "Service",
  props: {
    banner: String,
    child: {
      title: String,
      subTitle: String,
      imgSrc: String,
    },
    bulletsPoints: Array,
  },
};














import Vue from "vue";
import TermsForm from "@/components/legal/form/TermsForm.vue";

export default Vue.extend({
  name: "Terms",
  components: { TermsForm },
});

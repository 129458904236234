<template>
  <v-container class="pa-0">
    <v-form v-model="valid" style="width: 100%" ref="form">
      <v-tabs v-model="tab" grow background-color="transparent" hide-slider>
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          active-class="cuts-tab-active"
          class="cuts-tab text-md-h4 text-sm-h6 white--text rounded-t-xl"
          style="width: 50%"
        >
          EU
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="background: transparent; width: 100%">
        <v-tab-item class="cuts-frame">
          <Cuts v-if="loaded" :cuts="cuts.eu" />
        </v-tab-item>
      </v-tabs-items>
      <v-container class="cuts-frame-submit">
        <v-row justify="center">
          <v-btn
            :disabled="!valid"
            class="mr-4"
            color="success"
            @click="configureCuts"
          >
            Configure
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import Cuts from "@/components/administration/cuts/Cuts.vue";
import Vue from "vue";

export default Vue.extend({
  name: "CutsConfig",
  components: { Cuts },
  data: () => ({
    valid: false,
    currentRegionEu: true,
    tab: null,
    cuts: {},
    loaded: false,
  }),
  async mounted() {
    await this.$store.dispatch("getCutsData");
    this.loaded = true;
    await this.handleLoadedCutsData();
  },
  methods: {
    handleLoadedCutsData() {
      let data = this.$store.state.cutsData;
      if (data) {
        this.cuts = {
          eu: {
            mplus: {
              adv: data.eu.mplus.adv,
              booster: data.eu.mplus.booster,
              mcut: data.eu.mplus.mcut,
              bs: data.eu.mplus.bs,
              maxbs: data.eu.mplus.maxbs,
            },
            raid: {
              adv: data.eu.raid.adv,
              booster: data.eu.raid.booster,
              leader: data.eu.raid.leader,
              mcut: data.eu.raid.mcut,
              bs: data.eu.raid.bs,
              maxbs: data.eu.raid.maxbs,
            },
            shift: {
              adv: data.eu.shift.adv,
              booster: data.eu.shift.booster,
              leader: data.eu.shift.leader,
              mcut: data.eu.shift.mcut,
              bs: data.eu.shift.bs,
              maxbs: data.eu.shift.maxbs,
            },
            mythic: {
              adv: data.eu.mythic.adv,
              booster: data.eu.mythic.booster,
              mcut: data.eu.mythic.mcut,
              bs: data.eu.mythic.bs,
              maxbs: data.eu.mythic.maxbs,
            },
            pvp: {
              adv: data.eu.pvp.adv,
              booster: data.eu.pvp.booster,
              mcut: data.eu.pvp.mcut,
              bs: data.eu.pvp.bs,
              maxbs: data.eu.pvp.maxbs,
            },
            legacy: {
              adv: data.eu.legacy.adv,
              booster: data.eu.legacy.booster,
              mcut: data.eu.legacy.mcut,
              bs: data.eu.legacy.bs,
              maxbs: data.eu.legacy.maxbs,
            },
            mounts: {
              adv: data.eu.mounts.adv,
              booster: data.eu.mounts.booster,
              mcut: data.eu.mounts.mcut,
              bs: data.eu.mounts.bs,
              maxbs: data.eu.mounts.maxbs,
            },
            leveling: {
              adv: data.eu.leveling.adv,
              booster: data.eu.leveling.booster,
              mcut: data.eu.leveling.mcut,
              bs: data.eu.leveling.bs,
              maxbs: data.eu.leveling.maxbs,
            },
            delves: {
              adv: data.eu.delves.adv,
              booster: data.eu.delves.booster,
              mcut: data.eu.delves.mcut,
              bs: data.eu.delves.bs,
              maxbs: data.eu.delves.maxbs,
            },
          },
        };
      }
    },
    async configureCuts() {
      this.$refs.form.validate();
      const cuts = {
        user: this.$store.state.user.id,
        eu: {
          mplus: {
            adv: this.cuts.eu.mplus.adv,
            booster: this.cuts.eu.mplus.booster,
            mcut: this.cuts.eu.mplus.mcut,
            bs: this.cuts.eu.mplus.bs,
            maxbs: this.cuts.eu.mplus.maxbs,
          },
          raid: {
            adv: null,
            booster: null,
            leader: null,
            mcut: null,
            bs: null,
            maxbs: null,
          },
          shift: {
            adv: null,
            booster: null,
            leader: null,
            mcut: null,
            bs: null,
            maxbs: null,
          },
          mythic: {
            adv: this.cuts.eu.mythic.adv,
            booster: this.cuts.eu.mythic.booster,
            mcut: this.cuts.eu.mythic.mcut,
            bs: this.cuts.eu.mythic.bs,
            maxbs: this.cuts.eu.mythic.maxbs,
          },
          pvp: {
            adv: this.cuts.eu.pvp.adv,
            booster: this.cuts.eu.pvp.booster,
            mcut: this.cuts.eu.pvp.mcut,
            bs: this.cuts.eu.pvp.bs,
            maxbs: this.cuts.eu.pvp.maxbs,
          },
          legacy: {
            adv: this.cuts.eu.legacy.adv,
            booster: this.cuts.eu.legacy.booster,
            mcut: this.cuts.eu.legacy.mcut,
            bs: this.cuts.eu.legacy.bs,
            maxbs: this.cuts.eu.legacy.maxbs,
          },
          mounts: {
            adv: this.cuts.eu.mounts.adv,
            booster: this.cuts.eu.mounts.booster,
            mcut: this.cuts.eu.mounts.mcut,
            bs: this.cuts.eu.mounts.bs,
            maxbs: this.cuts.eu.mounts.maxbs,
          },
          leveling: {
            adv: this.cuts.eu.leveling.adv,
            booster: this.cuts.eu.leveling.booster,
            mcut: this.cuts.eu.leveling.mcut,
            bs: this.cuts.eu.leveling.bs,
            maxbs: this.cuts.eu.leveling.maxbs,
          },
          delves: {
            adv: this.cuts.eu.delves.adv,
            booster: this.cuts.eu.delves.booster,
            mcut: this.cuts.eu.delves.mcut,
            bs: this.cuts.eu.delves.bs,
            maxbs: this.cuts.eu.delves.maxbs,
          },
        },
      };
      await this.$emit("configureCuts", cuts);

      //this.$refs.form.reset();
    },
  },
});
</script>

<style scoped>
.cuts-tab-active {
  background: #6381b4 !important;
}
.cuts-tab {
  background: #6381b440;
}
.cuts-frame {
  border: 1px solid #6381b4;
  border-bottom: none;
  background: #6381b440;
}

.cuts-frame-submit {
  border: 1px solid #6381b4;
  border-top: none;
  background: #6381b440;
}
</style>















import Vue from "vue";
import ShiftEditForm from "@/components/raidmanagement/form/ShiftEditForm.vue";

export default Vue.extend({
    name: "ShiftEdit",
    components: { ShiftEditForm }
});

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import helpers from '@/helpers/helper';
import {
    faTwitter,
    faDiscord,
    faFacebook
} from '@fortawesome/free-brands-svg-icons';

library.add(faTwitter);
library.add(faDiscord);
library.add(faFacebook);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.mixin({
    methods: {
        formatPrice (price) {
            return helpers.formatPrice(price)
        }
    }
});

new Vue({
    router,
    store,
    vuetify,
    beforeCreate() {
        this.$store.commit('initialiseStore');
    },
    render: h => h(App)
}).$mount('#app');

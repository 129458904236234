













import Vue from "vue";
import ShiftBookingAndHistorySheetForm from "@/components/booking/form/ShiftBookingAndHistorySheetForm.vue";

export default Vue.extend({
  name: "ShiftBookingAndHistory",
  components: { ShiftBookingAndHistorySheetForm },
});







































































































































































import { factions, realmOptions } from "@/shared/constants";
import Vue from "vue";
import GoldTextField from "@/components/custom/gold-text-field";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { formatPotToGold } from "@/shared/formatPotToGold";
import SummaryOverlay from "@/components/booking/form/custom/SummaryOverlay.vue";

export default Vue.extend({
  name: "MythicForm",
  components: { SummaryOverlay, GoldTextField },
  data: () => ({
    valid: false,
    faction: "Horde",
    factions: factions,
    bosses: [],
    pot: "",
    buyer: "",
    paymentRealm: "",
    paymentRealmOptions: realmOptions,
    loading: false,
    snackbar: false,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    textRules: [
      (v: string) => !!v || "Field is required",
      (v: string) =>
        (!!v && v.replace(/\s/g, "").length > 1) ||
        "Name must be at least 1 non-whitespace character",
    ],
    required: [(v: any) => !!v || "Field is required"],
    paymentRealmRule: [
      (v: any) => !!v || "Field is required",
      (v: any) => (!!v && realmOptions.includes(v)) || "Unknown Realm selected",
    ],
  }),
  methods: {
    setPaymentRealm(e) {
      const value = e.target.value;
      if (value.includes("-")) {
        this.paymentRealm = value.split("-")[1];
      }
    },
    getFactionIcon(item: string) {
      if (item === "Horde") {
        return require("@/assets/Logo/horde_small.png");
      } else if (item === "Alliance") {
        return require("@/assets/Logo/alliance_small.png");
      }
    },
    resetForm: function () {
      const form: any = this.$refs.form;
      form.reset();
    },
    snackbarToggle(snackbarData: SnackbarData): void {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    async bookCustomer(shouldBook: boolean) {
      const form: any = this.$refs.form;
      if (shouldBook) {
        this.loading = true;
        form.validate();
        let section = "mythic";
        const response = await this.$store.dispatch("createBooking", {
          section: section,
          faction: this.faction,
          title: this.bosses,
          pot: parseInt(this.pot).toString(),
          buyer: this.buyer,
          payrealm: this.paymentRealm,
        });
        if (response.status >= 200 && response.status < 400) {
          this.snackbarToggle({
            message: "Your booking has been successfully created.",
            color: "green",
          });
        } else {
          this.snackbarToggle({
            message: "Your booking failed! Please try again later.",
            color: "red",
          });
        }
        this.loading = false;
      }
    },
  },
  computed: {
    computeSummaryValues: function (): Array<{ label: string; value: any }> {
      const values: any = [];
      values.push({ label: "Faction", value: this.faction });
      values.push({ label: "Customer", value: this.buyer });
      values.push({
        label: "Price",
        value: formatPotToGold((parseFloat(this.pot) * 1000).toString()),
      });
      values.push({ label: "Payment Realm", value: this.paymentRealm });
      return values;
    },
  },
  // mounted: function() {
  //     this.$nextTick(function() {
  //         this.advCut = this.$store.state.roles.some((role) => role === "TAKENBOOKER") ? "" : memberCutOptions[0];
  //         this.advCutOptions = this.$store.state.roles.some((role) => role === "TAKENBOOKER") ? mplusAdvCutOptions : memberCutOptions;
  //     });
  // },
  watch: {
    paymentRealm: function () {
      if (this.paymentRealm) {
        let realm = "";
        for (let i = 0; i < this.paymentRealm.length; i++) {
          realm += this.paymentRealm[i];
          let found = realmOptions.filter((pr) => {
            const paymentRealm = pr.toLowerCase().replaceAll("'", "");
            const input = realm.toLowerCase().replaceAll("'", "");
            return paymentRealm.includes(input);
          });
          if (found.length === 1) {
            i = this.paymentRealm.length + 1;
            this.paymentRealm = found[0];
          }
        }
      }
    },
    faction: function (val) {
      if (val) {
        let paymentFaction = "";
        for (let i = 0; i < val.length; i++) {
          paymentFaction += val[i];
          let found = factions.filter((co) => {
            const faction = co.toLowerCase().replaceAll("'", "");
            const input = paymentFaction.toLowerCase().replaceAll("'", "");
            return faction.includes(input);
          });
          if (found.length === 1) {
            i = this.faction.length + 1;
            this.faction = found[0];
          }
        }
      }
    },
  },
});





















































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { Customer, GoldCollector, LootType, Raid, RaidDifficulties } from "@/interfaces/Raids";
import RaidManagementMenu from "@/components/raidmanagement/RaidManagementMenu.vue";
import { difficultyOptions, typeOptions } from "@/shared/constants";

export default Vue.extend({
    name: "RaidCollectionsForm",
    components: { RaidManagementMenu },
    data: () => ({
        runs: [] as Array<Raid>,
        valid: false,
        selectedRun: {} as Raid,
        loading: false,
        snackbar: false,
        snackbarText: "Should not show",
        snackbarColor: "red",
        timeout: 2000,
        goldCollectors: [] as Array<GoldCollector>,
    }),
    methods: {
        snackbarToggle(snackbarData: SnackbarData) {
            this.snackbarText = snackbarData.message;
            this.snackbarColor = snackbarData.color;
            this.snackbar = true;
        },
        async loadRuns() {
            this.runs = this.$store.state.raids || [];
        },
        getCollection(gc: string, difficulty: RaidDifficulties, type: LootType, all = false) {
            const raids = this.runs.filter((raid: Raid) => {
                if (raid.gc === gc) {
                    return (raid.difficulty === difficulty && raid.type === type) || all;
                }
                return false;
            });

            let sum = 0;

            raids.forEach((raid: Raid) => {
                sum += raid.Buyer.filter((customer: Customer) => {
                    return raid.completed && customer.completed && customer.paid;
                }).length;
                if (raid.Buyer) {
                    const backups = raid.Backup as Array<Customer>;
                    sum += backups.filter((customer: Customer) => {
                        return raid.completed && customer.completed && customer.paid;
                    }).length;
                }
            })

            return sum;
        },
        copyAll() {
            let copy = "";
            this.goldCollectors.forEach((gc: GoldCollector) => {
                copy += `${gc.displayName}: ${this.getCollection(gc.userID,'Normal','VIP', true)} collections\r`
            });
            navigator.clipboard.writeText(copy);
        },
    },
    computed: {
        difficultyOptions() {
            return difficultyOptions;
        },
        typeOptions() {
            return typeOptions;
        }
    },
    mounted: function() {
        this.$nextTick(function() {
            this.$store.dispatch("getRaids");
            const gcLoad = setInterval(() => {
                this.goldCollectors = this.$store.state.goldCollectors;
                if (this.goldCollectors.length > 0) {
                    clearInterval(gcLoad)
                }
            }, 500)
            const raidLoad = setInterval(() => {
                this.loadRuns();
                if (this.runs.length > 0) {
                    clearInterval(raidLoad)
                }
            }, 500)
        });
    },
    // watch: {
    //     runs: {
    //         handler(value) {
    //             setTimeout(() => {
    //                 this.$store.dispatch("getRaids");
    //                 this.loadRuns();
    //                 this.goldCollectors = this.$store.state.goldCollectors;
    //             }, 10000);
    //         }
    //     }
    // }
});

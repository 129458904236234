













import Vue from "vue";
import MythicPlusForm from "@/components/booking/form/MythicPlusForm.vue";

export default Vue.extend({
  name: "MythicPlus",
  components: { MythicPlusForm },
});
























import Vue from "vue";
import CompletedCancelledOverviewList from "@/components/raidmanagement/CompletedCancelledOverviewList.vue";

export default Vue.extend({
  name: "CompletedCancelledOverview",
  components: { CompletedCancelledOverviewList },
});

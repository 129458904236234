













import Vue from "vue";
import RaidEditForm from "@/components/raidmanagement/form/RaidEditForm.vue";

export default Vue.extend({
    name: "RaidEdit",
    components: { RaidEditForm }
});

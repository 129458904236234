import axios from "axios";
import store from "@/store/index";
const jwtInterceptor = axios.create({});

jwtInterceptor.interceptors.request.use((config) => {
  config.withCredentials = true;
  return config;
});

jwtInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      const response = await axios
        .post(process.env.VUE_APP_OBLIVION_API + "/authentication/refreshtoken")
        .catch(() => {
          store.dispatch("logout");
        });
      if (!response) {
        return;
      }
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

export default jwtInterceptor;

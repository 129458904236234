<template>
  <v-container
    style="background: #1f2e53; min-width: 100%"
    class="fill-height ma-0"
  >
    <v-row style="width: 100%">
      <transaction-row
        v-for="(atd, i) in transactions"
        :key="'raid' + i"
        :transaction="atd"
      />
      <v-col
        cols="12"
        class="text-center"
        v-if="!transactions || transactions.length === 0"
      >
        <v-icon size="128px" color="white" class="justify-center"
          >mdi-folder-outline</v-icon
        >
        <p class="text-h3 white--text">No Transactions Found</p>
        <p class="text-h6 white--text">
          It can take up to 15 minutes, after a transaction is committed, to be
          visible here.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TransactionRow from "@/components/booster/attendances/rows/TransactionRow";
export default {
  name: "TransactionAttendance",
  components: { TransactionRow },
  props: ["transactions"],
};
</script>

<style scoped></style>

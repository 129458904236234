





















































































import Vue from "vue";
import { Cycle } from "@/interfaces/Cycle";
import { Cuts } from "@/interfaces/Cuts";
import { LoyaltyPointsRequest } from "@/interfaces/LoyaltyPointsRequest";
import CycleConfig from "@/components/administration/cycle/CycleConfig.vue";
import CutsConfig from "@/components/administration/cuts/CutsConfig.vue";
import LoyaltySystem from "@/components/administration/loyaltySystem/loyaltySystem.vue";
import AddOrDeleteItemsForm from "@/components/loyaltysystem/AddOrDeleteItemsForm.vue";
import AdminOrderView from "@/components/loyaltysystem/AdminOrderView.vue";

export default Vue.extend({
  name: "Administration",
  components: {
    CycleConfig,
    CutsConfig,
    LoyaltySystem,
    AddOrDeleteItemsForm,
    AdminOrderView,
  },
  data: () => ({
    tab: null,
    snackbar: false,
    snackbarText: "Should not show",
    timeout: 2000,
  }),
  methods: {
    async configureCycle(cycle: Cycle) {
      await this.$store.dispatch("configureCycle", cycle);
      this.snackbarToggle("Configured Cycle Dates!");
    },
    async configureCuts(cuts: Cuts) {
      await this.$store.dispatch("configureCuts", cuts);
      this.snackbarToggle("Configured Cuts!");
    },
    async createManualLoyaltyRequest(
      loyaltyPointsRequest: LoyaltyPointsRequest
    ) {
      await this.$store.dispatch(
        "createManualLoyaltyRequest",
        loyaltyPointsRequest
      );
      this.snackbarToggle("Send loyalty points request!");
    },
    snackbarToggle(message: string) {
      this.snackbarText = message;
      this.snackbar = true;
    },
  },
});














import Vue from "vue";
import PrivacyForm from "@/components/legal/form/PrivacyForm.vue";

export default Vue.extend({
  name: "Privacy",
  components: { PrivacyForm },
});

<template>
  <v-col cols="12">
    <v-row
      align="center"
      justify="center"
      style="border-bottom: 1px solid #6381b4"
    >
      <v-col md="3">
        <div class="white--text text-sm-body-1 text-center">
          {{ transaction.date }}
        </div>
      </v-col>
      <v-col md="3">
        <div class="white--text text-sm-body-1 text-center">
          {{ transaction.reason }}
        </div>
      </v-col>
      <v-col md="3">
        <div
          :class="
            transaction.transferred < 0
              ? 'white--text text-sm-body-1 text-center'
              : 'red--text text-sm-body-1 text-center'
          "
        >
          {{ formatGold(transaction.transferred) }}
          <div class="grey--text text-sm-subtitle-1 text-center">Amount</div>
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ transaction.explanation }}
        </div>
      </v-col>
      <v-col md="1">
        <v-icon v-if="transaction.approve === 'TRUE'" color="green" size="32"
          >mdi-check</v-icon
        >
        <v-icon v-else color="white" size="32">mdi-help</v-icon>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { formatPotToGold } from "@/shared/formatPotToGold";
import Vue from "vue";

export default Vue.extend({
  name: "TransactionRow",
  props: ["transaction"],
  methods: {
    formatGold(value) {
      const ivnerted = value * -1;
      return formatPotToGold(ivnerted.toString());
    },
  },
});
</script>

<style scoped></style>

<template>
  <div style="background: linear-gradient(#000923, #181819)">
    <v-img
      src="https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg"
      style="height: 100vh"
    >
      <v-container fluid fill-height>
        <v-layout justify-center align-center column pa-5>
          <div
            class="font-weight-black white--text text-xs-center mb-3 text-sm-h1"
            style="user-select: none"
          >
            404
          </div>
          <div
            class="text-sm-h3 font-weight-black white--text text-xs-center"
            style="user-select: none"
          >
            You found a squirrel eating an almond
          </div>
        </v-layout>
      </v-container>
    </v-img>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped></style>

<template>
  <v-container
    style="background: #1f2e53; min-width: 100%"
    class="fill-height ma-0"
  >
    <v-row style="width: 100%">
      <advertisement-row
        v-for="(atd, i) in advAttendances"
        :key="'adv' + i"
        :attendance="atd"
      />
      <v-col
        cols="12"
        class="text-center"
        v-if="!advAttendances || advAttendances.length === 0"
      >
        <v-icon size="128px" color="white" class="justify-center"
          >mdi-folder-outline</v-icon
        >
        <p class="text-h3 white--text">No Advertisements Found</p>
        <p class="text-h6 white--text">
          It can take up to 15 minutes, after attendance is posted in the
          discord, to be visible here.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AdvertisementRow from "@/components/booster/attendances/rows/AdvertisementRow";
export default {
  name: "AdvertisementAttendance",
  components: { AdvertisementRow },
  props: ["advAttendances"],
};
</script>

<style scoped></style>













































































import Vue from "vue";

export default Vue.extend({
  name: "RaidBookingMenu",
  props: {
    current: {
      required: true,
      type: String,
    },
  },
  methods: {
    redirect: function (event) {
      this.$router.push({
        name: "raidOverviewByRole",
        params: { type: event },
      });
    },
  },
  computed: {
    userRoles: function (): Array<string> {
      return this.$store.state.roles;
    },
    isManager: function (): boolean {
      return this.userRoles.some((role) => role === "RAIDMANAGER");
    },
    isAdmin: function (): boolean {
      return this.userRoles.some((role) => role === "SUPERADMIN");
    },
  },
});

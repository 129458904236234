













import ShiftRecurringRunsForm from "@/components/raidmanagement/form/ShiftRecurringRunsForm.vue";
import Vue from "vue";

export default Vue.extend({
  name: "ShiftRecurringRuns",
  components: { ShiftRecurringRunsForm },
});

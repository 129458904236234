<template>
  <v-card flat style="background: #1d2945" class="pa-5">
    <v-form v-model="valid" ref="form">
      <v-text-field
        v-model="discordId"
        :rules="discordIdRules"
        label="DiscordID"
        required
        dark
      ></v-text-field>
      <v-btn
        :disabled="!valid"
        :style="
              (
                valid
              )
                ? 'background-color: #1976d2 !important; color: #fff !important;' 
                : 'background-color: rgba(183, 183, 183, 0.7) !important; color: rgba(0, 0, 0, 0.26) !important'
        "
        color="success"
        class="mt-2"
        @click="viewAsBooster"
      >
        View as Member
      </v-btn>
    </v-form>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "ViewAsBooster",
  data: () => ({
    valid: false,
    discordId: "",
    discordIdRules: [
      (id) => !!id || "DiscordId is required!",
      (id) =>
        new RegExp(/(^[0-9]+$)/).test(id) ||
        "DiscordId has to consist of numbers",
    ],
  }),
  methods: {
    viewAsBooster() {
      this.$refs.form.validate();
      this.$emit("loadAsBooster", this.discordId);
      this.$refs.form.reset();
    },
  },
});
</script>

<style scoped></style>















import Vue from "vue";
import ShiftGoldOverviewForm from "@/components/raidmanagement/form/ShiftGoldOverviewForm.vue"

export default Vue.extend({
  name: "ShiftGoldOverview",
  components: { ShiftGoldOverviewForm },
});

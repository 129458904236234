





















































































































































































































































































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import { dayOptions, difficultyOptions, raidOptions, raidTypeOptions, shiftBossOptions, daysOfWeek } from "@/shared/constants";
import RaidManagementMenu from "@/components/booking/RaidBookingMenu.vue";
import moment from "moment-timezone";
import { GoldCollector, Leader, OfferedBosses, RaidDifficulties, Day } from "@/interfaces/Raids";
import { Cycle, CycleOption } from "@/interfaces/Cycle";

export default Vue.extend({
    name: "ShiftRecurringRunsForm",
    components: { RaidManagementMenu },
    data: () => ({
        valid: false,
        cycle: null,
        recurringRaids: [
            {
                day: "",
                dayOptions: dayOptions,
                start: "",
                end: "",
                duration: "",
                raid: "",
                raidOptions: raidOptions,
                raidBoss: "",
                raidBossOptions: ["11/11", "10/11", "9/11", "8/11", "7/11", "Custom"],
                gc: [],
                leader: "",
                offeredBosses: [
                {
                    boss: "",
                    possibleCustomers: 0,
                    bossOptions: shiftBossOptions
                }],
                difficulty: difficultyOptions[1],
                difficultyOptions: difficultyOptions,
                raidType: raidTypeOptions[0],
                raidTypeOptions: raidTypeOptions,
                amountOfPossibleCustomer: 0,
                amountOfBooster: 0,
            }],
        note: "",
        loading: false,
        snackbar: false,
        snackbarText: "Should not show",
        snackbarColor: "red",
        goldCollectors: [] as Array<GoldCollector>,
        leaders: [] as Array<Leader>,
        dayOptions: dayOptions,
        difficultyOptions: difficultyOptions,
        difficultyFilter: [] as Array<RaidDifficulties>,
        dayFilter: [] as Array<Day>,
        leaderNameFilter: [] as Array<Leader>,
        timeout: 2000,
        textRules: [
            (v: string) => !!v || "Field is required",
            (v: string) =>
                (!!v && v.replace(/\s/g, "").length > 1) ||
                "Name must be at least 1 non-whitespace character"
        ],
        required: [(v: any) => !!v || "Field is required"],
        arrayRequired: [
            (v: any) => !!v || "Field is required",
            (v: any) => (!!v && v.length > 0) || "Field is required"
        ]
    }),
    methods: {
        removeBoss(list: any, index: number) {
            list.splice(index, 1);
        },
        addBoss(list: any) {
            list.push({
                boss: "",
                possibleCustomers: 0,
                bossOptions: shiftBossOptions,
            });
        },
        removeRaid(index: number) {
            this.recurringRaids.splice(index, 1);
        },
        addRaid() {
            this.recurringRaids.push({
                day: dayOptions[0],
                dayOptions: dayOptions,
                start: "",
                end: "",
                duration: "",
                raid: "",
                raidOptions: raidOptions,
                raidBoss: "",
                offeredBosses: [
                {
                    boss: "",
                    possibleCustomers: 0,
                    bossOptions: shiftBossOptions
                }],
                raidBossOptions: ["11/11", "10/11", "9/11", "8/11", "7/11", "Custom"],
                gc: [],
                leader: "",
                difficulty: difficultyOptions[1],
                difficultyOptions: difficultyOptions,
                raidType: raidTypeOptions[0],
                raidTypeOptions: raidTypeOptions,
                amountOfPossibleCustomer: 0,
                amountOfBooster: 0,
            });
        },
        async createRecurringShifts() {
            if(!this.cycle){
                this.snackbarToggle({
                    message: "Please choose a cycle and then try again.",
                    color: "red"
                });
            }
            const response = await this.$store.dispatch(
                "createRecurringShifts", this.cycle
            );
            if (response.status >= 200 && response.status < 400) {
                this.snackbarToggle({
                    message: "Your raids have been successfully created.",
                    color: "green"
                });
            } else {
                this.snackbarToggle({
                    message: "Your creation failed! Please try again later.",
                    color: "red"
                });
            }
            this.loading = false;
        },
        async saveRecurringShifts() {
            const form: any = this.$refs.form;
            this.loading = true;
            form.validate();
            const recurringRaids = {
                recurringRaids: this.recurringRaids
            };
            const response = await this.$store.dispatch(
                "saveRecurringShifts",
                recurringRaids
            );
            if (response.status >= 200 && response.status < 400) {
                this.snackbarToggle({
                    message: "The raids has been successfully saved.",
                    color: "green"
                });
            } else {
                this.snackbarToggle({
                    message: "The raids couldn't be saved! Please try again later.",
                    color: "red"
                });
            }
            this.loading = false;
        },
        snackbarToggle(snackbarData: SnackbarData) {
            this.snackbarText = snackbarData.message;
            this.snackbarColor = snackbarData.color;
            this.snackbar = true;
        },
        async loadRuns() {
            const response = await this.$store.dispatch("getRecurringShifts", {});
            if (response.status >= 400) {
                this.$emit("snackbarToggle", {
                    message: "No available raid found for selected combination of faction, day and type",
                    color: "red accent-1"
                });
            }
            this.recurringRaids = response.data;
            this.recurringRaids.forEach(element => {
                element.dayOptions = dayOptions,
                element.raidOptions = raidOptions,
                element.difficultyOptions = difficultyOptions,
                element.raidTypeOptions = raidTypeOptions,
                element.offeredBosses.forEach(element => {
                    element.bossOptions = shiftBossOptions;
                });
            });
        }
    },
    computed: {
        cycleData: function(){
            return this.$store.state.cycleDateInfo
        },
        filteredRaids: function() {
            const raids: Array<any> = [];
            this.recurringRaids.forEach((raid: any) => {
                let filtered = true;

                if (this.difficultyFilter.length > 0 && filtered) {
                    if (this.difficultyFilter.includes(raid.difficulty)) filtered = true;
                    else filtered = false;
                }

                if (this.leaderNameFilter.length > 0 && filtered) {
                    if (this.leaderNameFilter.some(userID => userID.userID === raid.leader)) filtered = true;
                    else filtered = false;
                }

                if (this.dayFilter.length > 0 && filtered) {
                    if (this.dayFilter.includes(raid.day)) filtered = true;
                    else filtered = false;
                }

                if (filtered) {
                    raids.push(raid);
                }
            });
            return raids;
        },
        usedLeaders: function(): Array<any> {
            const  runs: Array<any> = this.recurringRaids;
            return runs.map((raid: any) => {
                return this.leaders.find((leader: Leader) => leader.userID === raid.leader);
            }).filter((leader: Leader|undefined) => {
                return leader !== undefined
            });
        },
        dateRangeOptions: function(){
            if(this.cycleData){
                let startCurrentCycle = moment(this.cycleData.currentStart).tz("Europe/Berlin");
                let endCurrentCycle = moment(this.cycleData.currentEnd).tz("Europe/Berlin");
                const dateRanges: Array<CycleOption> = [];
                const actualCurrentCycle: CycleOption = {
                    start: startCurrentCycle.clone().subtract(3, 'days').format("YYYY-MM-DD"),
                    end: endCurrentCycle.clone().subtract(3, 'days').format("YYYY-MM-DD"),
                    display: "Current (" + startCurrentCycle.clone().subtract(3, 'days').format("DD/MM") + " - " + endCurrentCycle.clone().subtract(3, 'days').format("DD/MM") + ")"
                };
                const currentCycle: CycleOption = {
                    start: startCurrentCycle.clone().add(4, 'days').format("YYYY-MM-DD"),
                    end: endCurrentCycle.clone().add(4, 'days').format("YYYY-MM-DD"),
                    display: "Future (" + startCurrentCycle.clone().add(4, 'days').format("DD/MM") + " - " + endCurrentCycle.clone().add(4, 'days').format("DD/MM") + ")"
                };

                const futureWeekCycle: CycleOption = {
                    start: startCurrentCycle.clone().add(11, 'days').format("YYYY-MM-DD"),
                    end: endCurrentCycle.clone().add(11, 'days').format("YYYY-MM-DD"),
                    display: "Future (" + startCurrentCycle.clone().add(11, 'days').format("DD/MM") + " - " + endCurrentCycle.clone().add(11, 'days').format("DD/MM") + ")"
                };

                const future2WeekCycle: CycleOption = {
                    start: startCurrentCycle.clone().add(18, 'days').format("YYYY-MM-DD"),
                    end: endCurrentCycle.clone().add(18, 'days').format("YYYY-MM-DD"),
                    display: "Future (" + startCurrentCycle.clone().add(18, 'days').format("DD/MM") + " - " + endCurrentCycle.clone().add(18, 'days').format("DD/MM") + ")"
                };

                const future3WeekCycle: CycleOption = {
                    start: startCurrentCycle.clone().add(25, 'days').format("YYYY-MM-DD"),
                    end: endCurrentCycle.clone().add(25, 'days').format("YYYY-MM-DD"),
                    display: "Future (" + startCurrentCycle.clone().add(25, 'days').format("DD/MM") + " - " + endCurrentCycle.clone().add(25, 'days').format("DD/MM") + ")"
                };

                const future4WeekCycle: CycleOption = {
                    start: startCurrentCycle.clone().add(32, 'days').format("YYYY-MM-DD"),
                    end: endCurrentCycle.clone().add(32, 'days').format("YYYY-MM-DD"),
                    display: "Future (" + startCurrentCycle.clone().add(32, 'days').format("DD/MM") + " - " + endCurrentCycle.clone().add(32, 'days').format("DD/MM") + ")"
                };

                const future5WeekCycle: CycleOption = {
                    start: startCurrentCycle.clone().add(39, 'days').format("YYYY-MM-DD"),
                    end: endCurrentCycle.clone().add(39, 'days').format("YYYY-MM-DD"),
                    display: "Future (" + startCurrentCycle.clone().add(39, 'days').format("DD/MM") + " - " + endCurrentCycle.clone().add(39, 'days').format("DD/MM") + ")"
                };
                dateRanges.push(actualCurrentCycle);
                dateRanges.push(currentCycle);
                dateRanges.push(futureWeekCycle);
                dateRanges.push(future2WeekCycle);
                dateRanges.push(future3WeekCycle);
                dateRanges.push(future4WeekCycle);
                dateRanges.push(future5WeekCycle);
                return dateRanges;
            }
            return [];
        },
    },
    mounted: function() {
        this.$store.dispatch("getGoldCollectors");
        this.$store.dispatch("getLeaders");
        this.$nextTick(function() {
            this.$store.dispatch("getCycleDate");
            this.loadRuns();
        });
    },
    watch: {
        '$store.state.goldCollectors': {
            deep: true,
            handler: function() {
                this.goldCollectors = this.$store.state.goldCollectors
            }
        },
        '$store.state.leaders': {
            deep: true,
            handler: function() {
                this.leaders = this.$store.state.leaders
            }
        }
    }
});














































import Vue from "vue";

export default Vue.extend({
  name: "SummaryOverlay",
  props: ["values", "valid"],
  data: () => ({
    dialog: false,
    bookDisabled: false,
  }),
  methods: {
    bookCustomer(shouldBook: boolean) {
      this.bookDisabled = true;
      this.dialog = false;
      this.$emit("closeOverlay", shouldBook);
      this.bookDisabled = false;
    },
  },
});

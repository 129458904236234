



































































































































import Vue, { PropType } from "vue";
import { Customer, Raid } from "@/interfaces/Raids";
import router from "@/router";
import { SnackbarData } from "@/interfaces/SnackbarData";
import RaidBookingInformationDialog from "@/components/booking/dialogs/RaidBookingInformationDialog.vue";

export default Vue.extend({
    name: "RaidManagementButtons",
    components: { RaidBookingInformationDialog },
    props: {
        edit: {
            type: Boolean,
            default: true
        },
        booking: {
            type: Boolean,
            default: true
        },
        cancel: {
            type: Boolean,
            default: true
        },
        info: {
            type: Boolean,
            default: true
        },
        overview: {
            type: Boolean,
            default: true
        },
        completed: {
            type: Boolean,
            default: true
        },
        overviewRole: {
            type: String,
            default: "leaderandgc"
        },
        raid: {
            type: Object as PropType<Raid>,
            required: true
        }
    },
    data: () => ({
        snackbar: false,
        snackbarText: "Should not show",
        snackbarColor: "red",
        dialog: false,
        confirmDialog: false,
        confirmDialogTitle: "",
        confirmDialogText: "",
        confirmDialogAction: ""
    }),
    computed: {
        raidActive: function(): boolean {
            return !this.raid.completed && this.raid.runStatus !== "Cancelled";
        },
        userRoles: function(): Array<string> {
            return this.$store.state.roles;
        },
        isManager: function(): boolean {
            return this.userRoles.some(
                (role) =>
                    role === "RAIDMANAGER" || role === "GCMANAGER" || role === "WEBACCESS"
            );
        },
        isAdmin: function(): boolean {
            return this.userRoles.some((role) => role === "ADMIN");
        },
        isAccess: function(): boolean {
            return this.userRoles.some((role) => role === "WEBACCESS");
        },
        ownRun: function() {
            if (this.isManager || this.isAdmin) {
                return true;
            } else {
                if (
                    this.raid.gc === this.$store.state.user.id ||
                    this.raid.leader === this.$store.state.user.id
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    },
    methods: {
        dialogUp() {
            this.dialog = true;
        },
        confirmDialogUp(type) {
            this.confirmDialog = true;
            if (type === "cancel") {
                this.confirmDialogTitle = `${
                    this.raid.runStatus !== "active" ? "Restore" : "Cancel"
                } confirmation`;
                this.confirmDialogText = `Do you really want to <b>${
                    this.raid.runStatus !== "active" ? "restore" : "cancel"
                }</b> the ${this.raid.id} raid?`;
                this.confirmDialogAction = "cancelRun";
            } else if (type === "complete") {
                const errors: Array<Customer> = [];
                if (!this.raid.completed) {
                    [...this.raid.Buyer, ...(this.raid.Backup || [])].forEach(
                        (c: Customer) => {
                            if (c.customerStatus === "In group") {
                                if (
                                    !c.completed ||
                                    !((c.goldWith?.length || "".length) > 0) ||
                                    !c.paid
                                ) {
                                    errors.push(c);
                                }
                            }
                        }
                    );
                }
                if (errors.length > 0) {
                    this.confirmDialogTitle = `Validation error`;
                    this.confirmDialogText = `There are ${errors.length} customer didn't marked as done / paid or the 'Gold with' field not filled, but marked as invited!`;
                    this.confirmDialogAction = "";
                } else {
                    this.confirmDialogTitle = `${
                        this.raid.completed ? "Uncomplete" : "Complete"
                    } confirmation`;
                    this.confirmDialogText = `Do you really want to mark ${
                        this.raid.id
                    } raid as <b>${
                        this.raid.completed ? "uncompleted" : "completed"
                    }</b>?`;
                    this.confirmDialogAction = "markRaid";
                }
            }
        },
        closeConfirmDialog() {
            this.confirmDialog = false;
            this.confirmDialogTitle = "";
            this.confirmDialogText = ``;
            this.confirmDialogAction = "";
        },
        executeFunction() {
            if (this.confirmDialogAction) {
                this[this.confirmDialogAction]();
            }
        },
        snackbarToggle(snackbarData: SnackbarData) {
            this.snackbarText = snackbarData.message;
            this.snackbarColor = snackbarData.color;
            this.snackbar = true;
        },
        async markRaid() {
            const raid: Raid = {
                ...this.raid,
                completed: !this.raid.completed
            };

            this.$store.dispatch("updateRaid", raid).then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    this.snackbarToggle({
                        message: "Raid sucessfully updated!",
                        color: "green"
                    });
                    Vue.nextTick(() => {
                        this.$store.dispatch("getRaids");
                    });
                } else {
                    this.snackbarToggle({
                        message: "Raid update failed! Please try again later.",
                        color: "red"
                    });
                }
            });
        },
        async cancelRun() {
            const raid: Raid = {
                ...this.raid,
                runStatus: this.raid.runStatus === "Cancelled" ? "active" : "Cancelled"
            };

            this.$store.dispatch("deleteRosterByRaid", {
                raidId: raid.id
            });
            this.$store.dispatch("updateRaid", raid).then((response) => {
                if (response.status >= 200 && response.status < 400) {
                    this.snackbarToggle({
                        message: "Raid sucessfully cancelled!",
                        color: "green"
                    });
                    Vue.nextTick(() => {
                        this.$store.dispatch("getRaids");
                    });
                } else {
                    this.snackbarToggle({
                        message: "Raid update failed! Please try again later.",
                        color: "red"
                    });
                }
            });
        }
    }
});


























































































































































































































































































































import Vue from "vue";
import { SnackbarData } from "@/interfaces/SnackbarData";
import {
  dayOptions,
  difficultyOptions,
  typeOptions,
  raidOptions,
} from "@/shared/constants";
import {
  Day,
  GoldCollector,
  Leader,
  Raid,
  RaidDifficulties,
  RaidOption,
} from "@/interfaces/Raids";
import moment from "moment-timezone";
import RaidScheduleItem from "@/components/raidmanagement/form/RaidScheduleItem.vue";

export default Vue.extend({
  name: "RaidScheduleForm",
  components: { RaidScheduleItem },
  data: () => ({
    runs: [] as Array<Raid>,
    valid: false,
    selectedRun: {} as Raid,
    loading: false,
    snackbar: false,
    typeOptions: typeOptions,
    difficulties: difficultyOptions,
    raidOptions: raidOptions,
    dayOptions: dayOptions,
    snackbarText: "Should not show",
    snackbarColor: "red",
    timeout: 2000,
    raidTypeFilter: null,
    difficultyFilter: [] as Array<RaidDifficulties>,
    raidFilter: [] as Array<RaidOption>,
    dayFilter: [] as Array<Day>,
    goldCollectors: [] as Array<GoldCollector>,
    leaders: [] as Array<Leader>,
    gcFilter: false,
    hasLead: false,
    leaderFilter: false,
    activeFilter: true,
    leaderNameFilter: [] as Array<Leader>,
    gcNameFilter: [] as Array<GoldCollector>,
    page: 1,
    limit: 7,
  }),
  methods: {
    step(step: number, fromInput: boolean) {
      this.page = fromInput
        ? parseInt(step + "")
        : this.page + parseInt(step + "");
      if (this.page < 1) this.page = 1;
      if (this.page > this.maxPage) this.page = this.maxPage;
    },
    resetForm() {
      const form: any = this.$refs.form;
      form.reset();
    },
    snackbarToggle(snackbarData: SnackbarData) {
      this.snackbarText = snackbarData.message;
      this.snackbarColor = snackbarData.color;
      this.snackbar = true;
    },
    async loadRuns() {
      this.runs = this.$store.state.allRaidsForSchedule;
    },
    copyCurrent() {
      let copy = "";
      this.filteredRaids.forEach((raid: Raid) => {
        copy += `${raid.difficulty} - ${raid.type} - ${raid.raid} - ${moment(
          raid.date
        ).format("MM-DD")} ${raid.time} ${raid.day} - <@${raid.leader}> - <@${
          raid.gc
        }> - Status: ${raid.runStatus} - Completed: ${raid.completed}\r\n`;
      });
      navigator.clipboard.writeText(copy);
    },
    async deleteEmptyRaids() {
      const response = await this.$store.dispatch("deleteEmptyRaids");
      if (response.status >= 200 && response.status < 400) {
        this.snackbarToggle({
          message: "The action got executed successfully",
          color: "green",
        });
      } else {
        this.snackbarToggle({
          message: "The action has failed! Please try again later.",
          color: "red",
        });
      }
    },
    isLocked() {
      return !this.userRoles.some(
        (role) =>
          role === "RAIDMANAGER" ||
          role === "GCMANAGER" ||
          role === "ADMIN" ||
          role === "GOLDCOLLECTOR" ||
          role === "RAIDLEADER" ||
          role === "PREMIUMRAIDLEADER"
      );
    },
  },
  computed: {
    userRoles: function (): Array<string> {
      return this.$store.state.roles;
    },
    isManager: function (): boolean {
      return this.userRoles.some(
        (role) =>
          role === "RAIDMANAGER" || role === "GCMANAGER" || role === "WEBACCESS"
      );
    },
    isAdmin: function (): boolean {
      return this.userRoles.some((role) => role === "ADMIN");
    },
    isAccess: function (): boolean {
      return this.userRoles.some((role) => role === "WEBACCESS");
    },
    overviewRole: function (): string {
      const isManager = this.userRoles.some((role) => role === "RAIDMANAGER");
      if (isManager) {
        return "manager";
      }
      return "leaderandgc";
    },
    usedGoldCollectors: function (): Array<any> {
      const runs: Array<Raid> = this.runs;
      return runs
        .map((raid: Raid) => {
          return this.goldCollectors.find(
            (gc: GoldCollector) => gc.userID === raid.gc
          );
        })
        .filter((gc: GoldCollector | undefined) => {
          return gc !== undefined;
        });
    },
    usedLeaders: function (): Array<any> {
      const runs: Array<Raid> = this.runs;
      return runs
        .map((raid: Raid) => {
          return this.leaders.find(
            (leader: Leader) => leader.userID === raid.leader
          );
        })
        .filter((leader: Leader | undefined) => {
          return leader !== undefined;
        });
    },
    pagedRaids: function (): Array<Raid> {
      const runs = this.filteredRaids;
      const start = (this.page - 1) * this.limit;
      return runs.length > this.limit
        ? runs.slice(start, start + this.limit)
        : runs;
    },
    filteredRaids: function (): Array<Raid> {
      const raids: Array<Raid> = [];
      this.runs.forEach((raid: Raid) => {
        let filtered = true;

        if (this.raidTypeFilter !== null && filtered) {
          if (this.raidTypeFilter === raid.type) filtered = true;
          else filtered = false;
        }

        if (this.difficultyFilter.length > 0 && filtered) {
          if (this.difficultyFilter.includes(raid.difficulty)) filtered = true;
          else filtered = false;
        }

        if (this.gcNameFilter.length > 0 && filtered) {
          if (this.gcNameFilter.some((userID) => userID.userID === raid.gc))
            filtered = true;
          else filtered = false;
        }

        if (this.leaderNameFilter.length > 0 && filtered) {
          if (
            this.leaderNameFilter.some(
              (userID) => userID.userID === raid.leader
            )
          )
            filtered = true;
          else filtered = false;
        }

        if (this.dayFilter.length > 0 && filtered) {
          if (this.dayFilter.includes(raid.day)) filtered = true;
          else filtered = false;
        }

        if (this.gcFilter && filtered) {
          if (raid.gc === "" || !raid.gc) filtered = true;
          else filtered = false;
        }

        if (this.raidFilter.length > 0 && filtered) {
          if (this.raidFilter.includes(raid.raid)) filtered = true;
          else filtered = false;
        }

        if (this.leaderFilter && filtered) {
          if (!this.hasLead) {
            if (raid.leader === null || raid.leader.length === 0)
              filtered = true;
            else filtered = false;
          } else {
            if (raid.leader !== null && raid.leader.length !== 0)
              filtered = true;
            else filtered = false;
          }
        }

        if (this.activeFilter && filtered) {
          if (!raid.completed && raid.runStatus !== "Cancelled")
            filtered = true;
          else filtered = false;
        }

        if (filtered) {
          raids.push(raid);
        }
      });
      return raids.sort((a, b) => {
        const dateA = moment(a.date, "YYYY-MM-DD");
        const dateB = moment(b.date, "YYYY-MM-DD");
        const timeA = moment(a.time, "HH:mm");
        const timeB = moment(b.time, "HH:mm");

        if (dateA.isBefore(dateB)) {
          return -1;
        } else if (dateA.isAfter(dateB)) {
          return 1;
        } else {
          return timeA.diff(timeB, "milliseconds");
        }
      });
    },
    maxPage: function () {
      const raids: Array<Raid> = this.filteredRaids;
      return Math.max(1, Math.ceil(raids.length / this.limit));
    },
  },
  beforeMount: function () {
    this.$store.dispatch("getAllRaidsForSchedule", {
      removeFilter: ["completed", "runStatus"],
      end: moment().tz("Europe/Berlin").add(24, "day").format("YYYY-MM-DD"),
    });
  },
  mounted: function () {
    this.$store.dispatch("getGoldCollectors");
    this.$store.dispatch("getLeaders");
  },
  watch: {
    "$store.state.allRaidsForSchedule": {
      deep: true,
      handler: function () {
        this.loadRuns();
      },
    },
    "$store.state.goldCollectors": {
      deep: true,
      handler: function () {
        this.goldCollectors = this.$store.state.goldCollectors;
      },
    },
    "$store.state.leaders": {
      deep: true,
      handler: function () {
        this.leaders = this.$store.state.leaders;
      },
    },
    raidTypeFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    leaderFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    activeFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    gcFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    dayFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    leaderNameFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    gcNameFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    difficultyFilter: {
      handler: function () {
        this.page = 1;
      },
    },
    raidFilter: {
      handler: function () {
        this.page = 1;
      },
    },
  },
});















import Vue from "vue";
import CycleHistoryForm from "@/components/booking/form/CycleHistoryForm.vue"

export default Vue.extend({
  name: "CycleHistory",
  components: { CycleHistoryForm },
});















































































































































































































































































































































































































































































import Vue from "vue";

export default Vue.extend({
  name: "Cuts",
  props: ["cuts"],
  data: () => ({
    valueRules: {
      required: (value: number) =>
        !!value || "Required and can only be a number.",
    },
  }),
  methods: {},
});















import Vue from "vue";
import StringHistoryForm from "@/components/booking/form/StringHistoryForm.vue"

export default Vue.extend({
  name: "StringHistory",
  components: { StringHistoryForm },
});

<template>
  <div class="oblivion-about-button">
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-layout
          align-center
          column
          class="d-flex justify-center"
          style="flex-direction: row"
        >
          <v-img v-if="imgSrc" contain :src="imgSrc" max-width="100px" />
          <div
            class="display-1 font-weight-bold white--text oblivion-link"
            style="text-align: center"
          >
            {{ text }}
            <v-icon
              v-if="text === 'APPLY NOW'"
              color="white"
              class="pl-1 oblivion-link"
              >mdi-open-in-new</v-icon
            >
          </div>
        </v-layout>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "AboutButton",
  props: {
    text: String,
    imgSrc: String,
  },
};
</script>

<style scoped>
.oblivion-about-button {
  background: #1f2024;
  border-bottom: 3px solid #6381b4;
  border-right: 3px solid #6381b4;
  border-radius: 5px;
  min-height: 50px;
}
</style>

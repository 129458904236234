<template>
  <v-col cols="12">
    <v-row
      align="center"
      justify="center"
      style="border-bottom: 1px solid #6381b4"
    >
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ missingMail.mailID }}
          <div class="grey--text text-sm-subtitle-1 text-center">
            Title of the Mail
          </div>
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ missingMail.characterName }}
          <div class="grey--text text-sm-subtitle-1 text-center">Recipient</div>
        </div>
      </v-col>
      <v-col md="2">
        <div
          :class="
            missingMail.delta < 0
              ? 'white--text text-sm-body-1 text-center'
              : 'red--text text-sm-body-1 text-center'
          "
        >
          {{ formatGold(missingMail.delta) }}
          <div class="grey--text text-sm-subtitle-1 text-center">Amount</div>
        </div>
      </v-col>
      <v-col md="2">
        <div class="white--text text-sm-body-1 text-center">
          {{ missingMail.realm }}
          <div class="grey--text text-sm-subtitle-1 text-center">Realm</div>
        </div>
      </v-col>
      <v-col md="4">
        <div class="white--text text-sm-body-1 text-center">
          {{ missingMail.characterName }}:{{ missingMail.delta }}:{{
            missingMail.mailID
          }}
          <div class="grey--text text-sm-subtitle-1 text-center">
            String to send
          </div>
        </div>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { formatPotToGold } from "@/shared/formatPotToGold";
import Vue from "vue";

export default Vue.extend({
  name: "MissingMailRow",
  props: ["missingMail"],
  methods: {
    formatGold(value) {
      const ivnerted = value * -1;
      return formatPotToGold(ivnerted.toString());
    },
  },
});
</script>

<style scoped></style>

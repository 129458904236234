












import Vue from "vue";
import CookiesForm from "@/components/legal/form/CookiesForm.vue";

export default Vue.extend({
  name: "Cookies",
  components: { CookiesForm },
});
